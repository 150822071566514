@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';


.btnRow {
  grid-area:      btnRow;
  padding-top:    .625rem;
  border-top:     solid .0625rem $DIVIDER_GREY;
  width:          100%;
  display:        flex;
  flex-direction: column;
  &.isAddon {
    padding-top: 0.75rem;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex-direction: row;
    padding-top:    1.5rem;

    &.isAddon {
      padding-top: 0.75rem !important;
    }
  }

  .addonWarning {
    font-size: 0.875rem;
  }

  .linkBtn {
    margin-bottom: .625rem;

    @media (min-width: $SCREEN_SM_DESKTOP) {
      margin-bottom: 0;
    }

    &:first-child {
      @media (min-width: $SCREEN_SM_DESKTOP) {
        margin-right: 1.875rem;
      }
    }

    &.cancel {
      color: $RED_ERROR;
    }

    &:last-child {
      @media (min-width: $SCREEN_TABLET) {
        margin-bottom: 0;
      }
    }
  }
}
