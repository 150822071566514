@import 'src/sass/themes/colors';
@import 'src/sass/buttons.module';
@import '../tile.module';


.qemTile {
  composes: tile;
  padding-top: 2rem;
  // margin-bottom: 1.5rem;
  flex: 50% 0;
  width: 100%;
  // max-width: 36rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    // max-width: 25rem;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    // max-width: 25rem;
    margin-right: 2.5rem;
    // margin-bottom: 2.5rem;
  }
}

// .productName {
//   margin-bottom: .5rem;
// }

.tagline {
  margin-bottom: 1.5rem;
}


.cancelTrialBox {
  position: absolute;
  right: 1rem;
  top: 1.5rem;
}

.trialHeading {
  font-family: $HAFFER;
  font-size: 1rem;
  color: $TAG_LINE;
  // margin-top: 1.5rem;
  // margin-bottom: 1rem;
  margin: 0.5rem 0;
}

.discountedPrice{
  margin-top: 0;
  margin-bottom: 1rem;
}

.remaining {
  font-family: $HAFFER;
  margin-bottom: .5rem;
}

.trialRemaining {
  font-family: $HAFFER;
  margin-bottom: 1rem;
}

.legal {
  font-size: .75rem;
  margin: .5rem 0 -1rem;
  font-family: $HAFFER;

  &.priceWrn {
    width: 75%;
  }
}

.trialHeading {
  font-family: $HAFFER;
  font-size: 1rem;
  color: $TAG_LINE;
  margin-bottom: 1rem;
}

.trialRemaining {
  font-family: $HAFFER;
}

.expiry {
  margin: 1rem 0 0;
}

.mobileDash {
  composes: dash;
  margin-top: 2rem;
}

.productAlert {
  margin:           1.125rem 0 1rem;
}

.appleIcon img {
  display: block;
  height: 2.5rem;
  margin-bottom: .5rem;
}


.googleIcon img {
  display: block;
  height: 2.5rem;
  margin-bottom: .5rem;
}


.subText {
  margin-top: .625rem;
  font-size: .625rem;
  font-family: $HAFFER;
  color: $TAG_LINE;
}


.secondaryHeading {
  font-family: $HAFFER;
  font-size: 1rem;
  color: $BLUE_HEADING;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}


.benefitList {
  font-family: $HAFFER;
  margin-bottom: 2rem;

  li {
    font-size: .75rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
    font-family: $HAFFER;
    color: $BLUE_HEADING;

    &:before {
      content: '';
      position: absolute;
      width: .375rem;
      height: .75rem;
      display: block;
      top: 0;
      left: -1.25rem;
      background-color: transparent;
      border-bottom: .125rem solid $GREEN_SEA;
      border-right: .125rem solid $GREEN_SEA;
      transform: rotate(45deg);
      border-top: 0;
    }
  }
}