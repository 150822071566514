@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';

.cancelledBox {
  border-radius:    .375rem;
  background-color: $CANCEL_YELLOW;
  color:            $TAG_LINE;
  font-family:      $HAFFER_BOLD;
  font-size:        .5rem;
  padding:          .25rem .5rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    font-size:        .75rem;
    padding:          .5rem .75rem;
  }
}
