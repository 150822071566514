@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import 'src/sass/layouts/main.module';
@import 'src/sass/themes/pallet-colors';


.pageLayout {
  height:           100%;
  background-color: $OFF_LIGHT;
  min-width:        20rem;
}


.greeting {
  width:         85%;
  font-family:   $HAFFER_BOLD;
  color:         $BLUE_HEADING;
  text-align:    center;
  font-size:     1.5rem;
  padding-top:   2rem;
  margin:        0 auto;
  text-overflow: ellipsis;
  overflow:      hidden;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    text-align:  center;
    padding-top: 4.0625rem;
    font-size:   1.875rem;
    max-width:   75rem;
    width:       100%;
  }

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    font-size:    2.5rem;
    text-align:   left;
    padding-left: 16.875rem;
  }
}


.mainArea {
  @extend .mainLayout;
  align-items:    stretch;
  padding-bottom: 5rem;
  flex:           1 0;
  margin-top:     2.25rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-top: 5rem;
    max-width: 78rem;
  }
}


.navWrapper {
  border-right: .0625rem solid $DIVIDER_GREY;
}
