@import 'src/sass/themes/colors';


.toggle {
  display:    block;
  cursor:     pointer;
  align-self: center;

  input {
    display: none;
    opacity: 0;
    width:   0;
    height:  0;
  }

  span {
    display:       block;
    position:      relative;
    align-self:    center;
    border-radius: .9375rem;

    &:before {
      content:       ' ';
      border-radius: 100%;
      position:      absolute;
      transition:    all 200ms ease-in;
    }
  }
}


.lightToggle {
  composes: toggle;

  input {
    &:checked + span:before {
      left:             1.25rem;
      background-color: $WHITE;
    }

    &:checked + span {
      background-color: $QKN_BLURPLE;
      border:           solid .0625rem $GREY_BORDER;
    }
  }

  span {
    width:            2.5rem;
    height:           1.25rem;
    background-color: $GREY_HEADER;
    border:           solid .0625rem $GREY_BORDER;

    &:before {
      width:            1rem;
      height:           1rem;
      background-color: $WHITE;
      top:              .0625rem;
      left:             .125rem;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16), 0px -1px 4px rgba(0, 0, 0, 0.05);
    }
  }
}


.darkToggle {
  composes: toggle;

  input {
      &:checked + span:before {
        left:             1.25rem;
        background-color: $WHITE;
        border:           solid .0625rem $BLACK;
      }
  }

  span {
    width:            2.5rem;
    height:           1.25rem;
    background-color: $BLACK;
    border:           solid .0625rem $BLACK;

    &:before {
      width:            1rem;
      height:           1rem;
      background-color: $WHITE;
      border:           solid .0625rem $BLACK;
      top:              .0625rem;
      left:             .125rem;
    }
  }
}


.toggleField {
  composes: toggle;

  input {
    &:checked + span:before {
      left:             .9375rem;
      background-color: $PURPLE_ACTIVE;
      border:           solid .0625rem $PURPLE_ACTIVE;
    }

    &:checked + span {
      background-color: $PURPLE_TOGGLE; //rgba(38,63,190,.38);
      border:           solid .0625rem $PURPLE_TOGGLE;
    }
  }

  span {
    width:            2.1875rem;
    height:           .875rem;
    background-color: $DIVIDER_GREY;
    border:           solid .0625rem $DIVIDER_GREY;

    &:before {
      width:            1.25rem;
      height:           1.25rem;
      border:           solid .0625rem $DIVIDER_GREY;
      background-color: $WHITE;
      top:              -.25rem;
      left:             -.0625rem;
      transition:       all 200ms ease-in;
    }
  }
}
