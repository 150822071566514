@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';


.mainLayout {
  display: flex;
  margin:  0 auto;
  width:   calc(100% - 2.5rem);
}


.leftSide {
  display: none;
  @media(min-width: $SCREEN_SM_DESKTOP) {
    display: block;
    flex:    0 1 14.375rem;
  }
}


.rightSide {
  flex: 1 0;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-left: 3.125rem;
    // margin: 0 1rem;
  }
}


.leftSide,
.rightSide {
  .headingText {
    font-weight:   normal;
    font-family:   $HAFFER;
    font-size:     1.125rem;
    margin-bottom: .9375rem;
    padding-left:  .5rem;

    @media(min-width: $SCREEN_SM_DESKTOP) {
      padding-left:  0;
      font-size:     1.5rem;
      margin-bottom: 2.5rem;
    }
  }
}


.heading {
  display:         flex;
  justify-content: space-between;
  color:           $BLUE_HEADING;
  margin-bottom:   1rem;
}


.support {
  display:        flex;
  flex-direction: column;
  font-family:    $HAFFER;
  text-align:     right;
}


.title {
  font-family:   $HAFFER;
  margin-bottom: .3125rem;
  font-size:     1.125rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    font-size: 1.5rem;
  }
}
