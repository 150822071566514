@import 'src/sass/themes/pallet-colors';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';


.formOnly {
  display:       none;
  
  &.show {
    display: block;
  }
}

.achForm {
  h4 {
    font-family:   $HAFFER_BOLD;
    font-size:     1.125rem;
    margin:        .9375rem 0 1.5625rem;
  }
}


.achRow {
  display:         flex;
  justify-content: space-between;
  flex-direction:  column;

  @media(min-width: $SCREEN_TABLET) {
    flex-direction: row;
    margin-bottom:  .9375rem;
  }

  div {
    margin-bottom: .9375rem;
    @media(min-width: $SCREEN_TABLET) {
      margin-bottom: 0;
    }
  }

  & > div {
    flex: 1 0;

    @media(min-width: $SCREEN_TABLET) {
      &:nth-child(1) {margin-right: .9375rem;}
      &:nth-child(2) {margin-left: .9375rem;}
    }
  }
}


.extra {
  display: none;

  @media(min-width: $SCREEN_TABLET) {
    display: block;
    width:   50%;
  }
}
