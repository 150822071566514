@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';


.label {
  font-family:   $HAFFER_BOLD;
  font-size:     1.125rem;
  color:         $TAG_LINE;
  margin-bottom: 1.5rem;
}


.name {
  font-size:     1.125rem;
  font-family:   $HAFFER_BOLD;
  margin-bottom: .5rem;
}


.quota {
  font-family:   $HAFFER;
  font-size:     .75rem;
  margin-bottom: 1.5rem;
  @media (min-width: $SCREEN_TABLET) {
    margin-bottom: 1rem;
  }

  &.stackedP {
    margin-bottom: 0px;
  }
}


.currentPlan {
  position:       relative;
  color:          $BLUE_HEADING;
  box-shadow:     0 .5rem 1rem .0625rem rgba(0, 0, 0, 0.16), 0 -.0625px .5px 0 rgba(0, 0, 0, 0.05);
  border-radius:  .875rem;
  margin-bottom:  1.25rem;
  padding:        2rem 1.25rem 1.75rem;
  display:        flex;
  flex-direction: column;

  @media (min-width: $SCREEN_TABLET) {
    flex-direction: row;
  }

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.standard):before,
  &:global(.volume200):before,
  &:global(.volume100):before,
  &:global(.volume50):before,
  &:global(.plus):before,
  &:global(.extra):before {
    background-color: $QKN_RED;
  }

  .name {
    margin-bottom: .25rem;
  }

  .leftSide {
    flex: 1 0;
  }

  .currentPrice {
    flex:          1 0;
    margin-bottom: 1rem;
    padding-right: 0.625rem;
  }

  .inactiveBtn,
  .activeBtn {
    flex:  0 1 2.25rem;
    width: 100%;
    @media (min-width: $SCREEN_TABLET) {
      flex: 0 1 9.75rem;
    }
  }
}


.inactiveBtn,
.activeBtn {
  width:         9.25rem;
  height:        2.25rem;
  border-width:  .125rem;
  border-style:  solid;
  border-radius: 1.25rem;
  color:         $WHITE;
  font-family:   $HAFFER_BOLD;
  font-size:     .875rem;
  outline:       none;
  cursor:        pointer;
}


.activeBtn {
  background-color: $QKN_BLURPLE;
  border-color:     $QKN_BLURPLE;
  color:            $WHITE;
}


.inactiveBtn {
  background-color: $WHITE;
  border-color:     $TAG_LINE;
  color:            $TAG_LINE;
  cursor:           not-allowed;
}


.benefit {
  font-family: $HAFFER;
  font-size:   .75rem;
  color:       $TAG_LINE;
}


.buttonCol {
  display:        flex;
  flex-direction: column;
  flex:           0 1;
}


.noCTA {
  background-color: $WHITE;
  border-color:     $TAG_LINE;
  color:            $TAG_LINE;
  width:            9.25rem;
  height:           2.25rem;
  border-width:     .125rem;
  border-style:     solid;
  border-radius:    1.25rem;
  font-size:        .875rem;
  font-family:      $HAFFER_BOLD;
  outline:          none;
  cursor:           not-allowed;
}
