@import 'src/sass/themes/sizes';
@import 'src/sass/themes/fonts';


.paymentOptions {
  display:         flex;
  width:           20rem;
  justify-content: space-between;
  margin:          0 auto 1.875rem;
  @media (min-width: $SCREEN_TABLET) {
    margin-bottom: 3.125rem;
  }

  button {
    border-radius:    0;
    background-color: transparent;
    cursor:           pointer;
    font-family:      $HAFFER;
    font-size:        .875rem;
    display:          flex;
    align-items:      center;
    border:           0;
    padding:          0 0 .625rem;
    border-bottom:    solid .125rem transparent;
    outline:          none;

    &.active {
      border-bottom: solid .125rem black;
    }

    img {
      margin-right: .5rem;
      height:       1.5rem;
      @media (min-width: $SCREEN_SM_DESKTOP) {
        height:       2rem;
        margin-right: .625rem;
      }
    }

    @media (min-width: $SCREEN_SM_DESKTOP) {
      font-size: 1.125rem;
    }
  }
}
