@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.contentLabel {
  font-size:   1.125rem;
  color:       $BLUE_HEADING;
  font-family: $HAFFER_BOLD;
}


.contentText {
  font-family:   $HAFFER;
  color:         $TAG_LINE;
  font-size:     .875rem;
  margin-top:    .375rem;
  text-overflow: ellipsis;
  overflow:      hidden;
  line-height:   1.5rem;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    margin-top:  .625rem;
    font-size:   1.125rem;
    line-height: 1.75rem;
  }

  & + p {
    margin-top: 0;
  }
}


.contentAction {
  color:            $QKN_BLURPLE;
  font-family:      $HAFFER;
  background-color: transparent;
  border:           0;
  padding:          0;
  margin-top:       .5rem;
  cursor:           pointer;
  outline:          none;
  font-size:        .875rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-top: 1rem;
    font-size:  1.125rem;
  }


  &:hover {
    text-decoration: underline;
  }
}


.card {
  background-color: $WHITE;
  border-radius:    .8125rem;
  box-shadow:       0 .75rem 1.5rem .125rem rgba(0, 0, 0, 0.24);
  padding:          2.75rem 3.125rem;
}
