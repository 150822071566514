@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';

.cancelDowngrade {
  display: flex;
  width: 100%;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid $QKN_BLURPLE;
  background: $ADDON_GREY;
  margin-bottom: 1.5rem;

  .downgradeMsg {
    .cancelDwngradeName,
    span {
      font-size: 0.8125rem;
    }
  }

  .topText {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  // &.mainTile {
  //   flex-direction: column;
  // }

  // &.changePlan {
  //   flex-direction: column;
  //   justify-content: space-between;
  //   @media (min-width: $SCREEN_TABLET) {
  //     flex-direction: row;
  //   }
  // }
}

:global(.changePlan) {
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $SCREEN_TABLET) {
    flex-direction: row;

    .topText {
      max-width: 50%;
    }
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    .topText {
      max-width: 65%;
    }
  }

  @media (min-width: $SCREEN_WIDE) {
    .topText {
      max-width: unset;
    }
  }
}