@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';

.mfaForm {
  display: grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'mfaLabel'
          'mfaInput'
          'passwordInput';

  @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'mfaLabel mfaLabel'
          'mfaInput passwordInput';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap: 2.625rem;
    grid-template-rows: 3.125rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.5rem;
  }
}

.mfaLabel {
  grid-area: mfaLabel;
}

.mfaInput {
  grid-area: mfaInput;
}

.passwordInput {
  grid-area: passwordInput;
}
