@import 'src/sass/themes/colors';

// Custom web fonts are not supported. Only use system installed fonts.

[data-braintree-id="choose-a-way-to-pay"] {
  color:       $LUCKY_GREY;
  font-family: OpenSans-Semibold, sans-serif;
}

[data-braintree-id="toggle"] {
  background-color: white;
  &:hover {
    background-color: white;
  }
}

:global(.braintree-form__hosted-field) {
  border-radius: 5rem;
}

:global(.braintree-form__label) {
  color: $MED_GREY !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: OpenSans-Semibold, sans-serif;
}

:global(.braintree-form__field) {
  border-radius: 5px;
}
