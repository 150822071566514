@import 'src/sass/themes/colors';
@import 'src/sass/buttons.module';

.curPaymentRow {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10rem;
  grid-template:
    'rowLeft'
    'rowRight';

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    grid-template:'rowLeft rowRight';
  }

  .payRowLeft {
    display: grid;
    grid-area: rowLeft;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    grid-column-gap: 1rem;
    align-items: center;

    @media (min-width: $SCREEN_NEW_DESKTOP) {
      grid-column-gap: 2rem;
      grid-template-columns: 60px 90px 1fr;
    }

    .last4,
    .productLineBadge {
      display: flex;
      align-items: center;
    }

    .productLineBadge {
      background-color: $ACTIVE_TAB;
      border-radius: 4px;
      font-size: 0.8125rem;
      padding: 0.25rem 0.5rem;
      max-width: max-content;
    }
  }


  &:not(:first-child) {
    padding-top: 1rem;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: solid 0.0625rem #CED0D3;
  }

  .imgContain {
    // width: 2.5rem;
    // height: auto;
    height: 2.5rem;
    width: auto;

    img {
      width: auto;
      height: 100%;
    }
  }

  .payRowRight {
    grid-area: rowRight;
    padding-top: 1rem;
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $SCREEN_NEW_DESKTOP) {
      // text-align: right;
      justify-content: flex-end;
    }
  }
}


