@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';


.ppWrapper {
  display:       none;
  margin-bottom: 1.25rem;

  &.show {
    display: block;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    margin-bottom: 3.75rem;
  }

  .ppBtnWrapper {
    width:  100%;
    margin: 0 auto 1.5625rem;

    @media (min-width: $SCREEN_TABLET) {
      display: flex;
      align-items: center;
    }

    .ppSide {
      flex: 1 0;
      align-self: flex-start;

      @media (min-width: $SCREEN_TABLET) {
        margin-right: 1.25rem;
      }

      p {
        font-size: .625rem;
        font-family: $HAFFER;
        margin-bottom: 1.25rem;
        text-align: center;
        @media (min-width: $SCREEN_TABLET) {
          margin-top: .625rem;
          margin-bottom: 0;
        }
        @media (min-width: $SCREEN_SM_DESKTOP) {
          text-align: left;
        }
      }
    }

    :global(#paypal-button) {

      @media (min-width: $SCREEN_SM_DESKTOP) {
        height: 2.8125rem;
      }

      @media (min-width: $SCREEN_NEW_DESKTOP) {
        height: 3.4375rem;
      }
    }

    .inputFields {
      flex: 1 0;

      @media (min-width: $SCREEN_TABLET) {
        margin-left: 1.25rem;
      }
    }
  }

  .ppHeading {
    font-size:      .875rem;
    font-family:    $HAFFER;
    padding-bottom: 1.5625rem;
    margin-bottom:  1.5625rem;
    text-align:     center;
    @media (min-width: $SCREEN_SM_DESKTOP) {
      padding-bottom: 0;

      &:last-child {
        margin-bottom: 2.5rem;
      }
    }
  }
}
