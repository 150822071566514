@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/fonts';


.newSelectField {
  display:        flex;
  flex-direction: column;
  position:       relative;
  font-family:    $HAFFER;

  label {
    font-size:        .75rem;
    color:            rgba(0, 0, 0, 0.6);
    position:         absolute;
    background-color: $WHITE;
    top:              -.5rem;
    left:             1rem;
    padding:          0 .5rem;

    &.hasError {color: $RED_ERROR;}
  }

  .selectField {
    padding:          .5rem;
    font-size:        .875rem;
    border:           .0625rem solid rgba(0, 0, 0, 0.38);
    border-radius:    .75rem;
    outline:          none;
    width:            100%;
    background-color: white;
    font-weight:      lighter;
    text-align:       left;
    transition:       border-radius ease 100ms;

    @media(min-width: $SCREEN_TABLET) {
      font-size: 1rem;
      padding:   .625rem;
    }

    @media(min-width: $SCREEN_SM_DESKTOP) {
      font-size: 1.125rem;
      padding:   1rem 1.5rem;
    }

    &:disabled {
      background-color: rgba(239, 239, 239, 0.3);
      color:            rgb(84, 84, 84);
      cursor:           not-allowed;
    }

    &.isOpen {
      border-radius: .75rem .75rem 0 0;
    }

    &:focus {
      outline: none;
    }

    &:after {
      content:          ' ';
      position:         absolute;
      right:            1.25rem;
      top:              .625rem;
      width:            .625rem;
      height:           .625rem;
      transition:       all ease-in .2s;
      background-color: transparent;
      border-bottom:    .125rem solid rgba(0, 0, 0, 0.38);
      border-right:     .125rem solid rgba(0, 0, 0, 0.38);
      transform:        rotate(45deg);

      @media(min-width: $SCREEN_TABLET) {
        right:  1.125rem;
        top:    .75rem;
        width:  .75rem;
        height: .75rem;
      }

      @media(min-width: $SCREEN_SM_DESKTOP) {
        right:  1.4375rem;
        top:    1.125rem;
        width:  .875rem;
        height: .875rem;
      }
    }

    &.isOpen:after {
      top:       .9375rem;
      transform: rotate(225deg);

      @media(min-width: $SCREEN_TABLET) {
        top: 1.1875rem;
      }
      @media(min-width: $SCREEN_SM_DESKTOP) {
        top: 1.5625rem;
      }
    }
  }

  ul {
    position:         absolute;
    top:              2.125rem;
    background-color: white;
    z-index:          1;
    font-size:        .625rem;
    width:            100%;
    border:           solid .0625rem rgba(0, 0, 0, 0.38);
    border-top:       0;
    max-height:       15rem;
    overflow:         auto;

    @media(min-width: $SCREEN_TABLET) {
      top: 2.5rem;
    }
    @media(min-width: $SCREEN_SM_DESKTOP) {
      top: 3.375rem;
    }

    li {
      cursor:      pointer;
      font-weight: lighter;
      text-align:  left;

      button {
        background-color: transparent;
        outline:          none;
        border:           0;
        padding:          .5rem .625rem;
        width:            100%;
        text-align:       left;
        font-size:        .75rem;
        font-family:      $HAFFER;
        @media(min-width: $SCREEN_SM_DESKTOP) {
          font-size:        1rem;
        }
      }

      &:hover {
        background-color: #007EFF14;
      }

      &.selected { background-color: #007EFF0A; }

      &.disabled {
        background-color: $WHITE;
        color:            $BORDER_GREY;
        cursor:           not-allowed;

        &:hover {
          background-color: $WHITE;
        }
      }
    }
  }

  p {
    color:      $RED_ERROR;
    text-align: left;
    font-size:  .75rem;
    height:     .75rem;
    @media(min-width: $SCREEN_TABLET) {
      font-size: .875rem;
      height:    .875rem;
    }
  }
}
