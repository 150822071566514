// $OPEN_SANS: 'OpenSans', sans-serif;
// $POPPINS: 'Poppins', sans-serif;
// $POPPINS_BOLD: 'PoppinsBold', sans-serif;
// $POPPINS_SEMI_BOLD: 'PoppinsSemiBold', sans-serif;
// $CIRCULAR_MEDIUM: 'circularMedium', sans-serif;
// $CIRCULAR_BLACK: 'circularBlack', sans-serif;
// $CIRCULAR_BOLD: 'circularBold', sans-serif;
// $CIRCULAR_BOOK: 'circularBook', sans-serif;
$HAFFER: 'Haffer', sans-serif;
$HAFFER_BOLD: 'HafferBold', sans-serif;

//thin to thick $OPEN_SANS $CIRCULAR_BOOK $CIRCULAR_MEDIUM $CIRCULAR_BOLD $CIRCULAR_BLACK;
