@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';

.spinner {
  margin: 3rem 0 2rem;
}

.mainWrapper {
  @media (min-width: $SCREEN_NEW_DESKTOP) {
    max-width: 61rem;
  }
}

.label {
  font-family:   $HAFFER_BOLD;
  font-size:     1.125rem;
  color:         $TAG_LINE;
  margin-bottom: 1.5rem;
}


.name {
  font-size:     1.125rem;
  font-family:   $HAFFER_BOLD;
  margin-bottom: .5rem;
}

.mostPopContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  font-family:   $HAFFER_BOLD;
}

.mostPopularTag {
  display: flex;
  align-items: center;
  color: $BLUE_HEADING;
  background: $LIGHT_BLUE_BG;
  border-radius: 6px;
  font-size: 11px;
  font-family: $HAFFER;
  line-height: 14px;
  text-transform: uppercase;
  height: 20px;
  width: 103px;
  justify-content: center;
}


.quota {
  font-family:   $HAFFER;
  font-size:     .75rem;
  margin-bottom: 1.5rem;
}


.optionArea {
  justify-content: space-between;

  @media (min-width: $SCREEN_TABLET) {
    display:               grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap:          1rem;
    grid-column-gap:       1rem;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    display: flex;
  }
}


.option {
  flex:            1 0;
  display:         flex;
  flex-direction:  column;
  position:        relative;
  color:           $BLUE_HEADING;
  box-shadow:      0 .5rem 1rem .0625rem rgba(0, 0, 0, 0.16), 0 -.0625px .5px 0 rgba(0, 0, 0, 0.05);
  border-radius:   .875rem;
  padding:         1.75rem 1.25rem 1.25rem;
  justify-content: space-between;
  align-self:      stretch;
  margin:          0 0 1rem;

  @media (min-width: $SCREEN_TABLET) {
    margin: 0;
  }

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.volume200):before,
  &:global(.volume100):before,
  &:global(.volume50):before,
  &:global(.plus):before,
  &:global(.lite):before,
  &:global(.basic):before {
    background-color: $QKN_RED;
  }
}


.optionPrice {
  margin-bottom: 1rem;
}
