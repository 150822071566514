@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';


.mainHeader {
  display:          flex;
  flex-direction:   column;
  box-shadow:       0 .25rem .625rem 0 rgba(0, 0, 0, 0.12);
  align-items:      center;
  flex:             0 1 3rem;
  background-color: $WHITE;
  z-index:          3;
  border-bottom:    solid .0625rem $DIVIDER_GREY;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    display: flex;
    flex:    0 1 5rem;
  }

  .mainHeaderWrapper {
    display:         flex;
    max-width:       75rem;
    width:           100%;
    margin:          0 auto;
    justify-content: space-between;
    align-items:     center;
    height:          100%;
    padding:         .625rem 0;
    @media(min-width: $SCREEN_SM_DESKTOP) {
      padding: .625rem 1.875rem;
    }
    @media(min-width: $SCREEN_WIDE) {
      padding: .625rem 0;
    }
  }

  .brandSide {
    display:     flex;
    align-items: center;
    margin-left: .875rem;
    @media(min-width: $SCREEN_SM_DESKTOP) {
      margin-left: 0;
    }

    img {
      height: 1.125rem;
      @media(min-width: $SCREEN_TABLET) {
        height: 1.5rem;
      }
      @media(min-width: $SCREEN_SM_DESKTOP) {
        height: 2rem;
      }
    }

    .vLine {
      width:            .0625rem;
      background-color: $DIVIDER_GREY;
      margin:           0 .9375rem;
      height:           1.75rem;

      @media(min-width: $SCREEN_SM_DESKTOP) {
        margin: 0 1.875rem;
        height: 2.875rem;
      }
    }
  }

  .userSide {
    display:        flex;
    font-family:    $HAFFER;
    text-transform: capitalize;
    color:          $QKN_BLURPLE;
    font-size:      1.125rem;

    p {
      display: none;
      @media(min-width: $SCREEN_TABLET) {
        display:       block;
        overflow:      hidden;
        text-overflow: ellipsis;
        white-space:   nowrap;
        max-width:     12.5rem;
      }
      @media(min-width: $SCREEN_NEW_DESKTOP) {
        max-width: 25rem;
      }
    }


    a {
      color:        $QKN_BLURPLE;
      margin-left:  .3125rem;
      margin-right: .3125rem;
    }
  }
}


.navBtn {
  border:           0;
  background-color: white;
  color:            $QKN_BLURPLE;
  font-family:      $HAFFER;
  outline:          none;
  cursor:           pointer;
  z-index:          3;
  width:            100%;
  text-align:       center;
  position:         relative;
  padding:          .9375rem 0;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display: none;
  }

  &:after {
    content:          '';
    position:         absolute;
    margin-left:      1.25rem;
    top:              1.125rem;
    width:            .625rem;
    height:           .625rem;
    transition:       all ease-in .2s;
    background-color: transparent;
    border-bottom:    .125rem solid $MED_GREY;
    border-right:     .125rem solid $MED_GREY;
    transform:        rotate(45deg);
  }
}


.overLay {
  background-color: black;
  width:            100%;
  height:           100%;
  position:         absolute;
  margin-top:       0;
  opacity:          .5;
  z-index:          2;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display: none;
  }
}


.miniNav {
  width:            100%;
  position:         absolute;
  background-color: white;
  top:              6rem;
  z-index:          3;

  &.hideNav {
    top: 3rem;
  }

  a, button {
    color:       $QKN_BLURPLE;
    font-family: $HAFFER;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    display: none;
  }
}
