@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';


.modalWrapper {
  max-width:  57.125rem;
  overflow:   scroll;
  height:     90%;
  position:   relative;
  min-height: 21.25rem;
  max-height: 54rem;

  @media(min-width: $SCREEN_TABLET) {
    max-height: 50rem;
  }
  @media(min-width: $SCREEN_SM_DESKTOP) {
    max-height: 43rem;
  }
  @media(min-width: $SCREEN_NEW_DESKTOP) {
    max-height: 45rem;
  }
}


.modalArea {
  display:        flex;
  flex-direction: column;
  padding:        1rem;
  position:       relative;
  composes:       modalBody;
  font-family:    $HAFFER;
  height:         calc(100% - 3.5rem);

  @media(min-width: $SCREEN_SM_DESKTOP) {
    height:  calc(100% - 3.25rem);
    padding: 1rem 2.75rem 1.375rem;
  }

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    height: calc(100% - 2.5rem);
  }
}


.upperMessage {
  flex:             0 1 4rem;
  font-size:        1rem;
  border-radius:    .75rem;
  margin-bottom:    1rem;
  background-color: $LIGHT_BLUE_BG;
  border:           solid .0625rem $LIGHT_BLUE_BRD;
  position:         relative;
  padding:          .5rem;
  display:          flex;
  justify-content:  center;

  @media(min-width: $SCREEN_TABLET) {
    padding: .75rem 2rem 0.5rem 3rem;
  }

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    flex: 0 1;
    padding: 0.75rem 5.125rem 0.75rem 6rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width:991px) and (max-width:1013px) {
    padding-right: 3rem;
  }

  img {
    display:  none;
    position: absolute;
    width:    1.75rem;
    height:   1.75rem;
    left:     .5rem;

    @media(min-width: $SCREEN_TABLET) {
      display: block;
    }

    @media(min-width: $SCREEN_NEW_DESKTOP) {
      top:    0.1rem;
      left:   1.5rem;
      width:  2.625rem;
      height: 2.625rem;
    }
  }

  img + p {
    font-weight:   bold;
    font-size:     1rem;
    line-height:   1rem;

    display: flex;
    align-items: center;

    @media(min-width: $SCREEN_SM_DESKTOP) {
      font-size:   1rem;
      line-height: 1.375rem;
    }
  }
}

.switchBorder {
  border: 0.063rem solid $DIVIDER_GREY;
  box-sizing: border-box;
  border-radius: 1.25rem;
}

.shrinkingAreaContainer {
  overflow: hidden;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    overflow: initial;
  }
}


.shrinkingArea {
  height:   100%;

  @media(max-height: 730px) {
    overflow: scroll;
  }

  @media(max-width: $SCREEN_SM_DESKTOP) {
    overflow: scroll;
  }

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    max-height: 22.5rem;
  }
}


.simpBranding {
  display:         flex;
  align-items:     center;
  justify-content: center;
  flex-direction:  column;
  margin-bottom:   1rem;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    margin-bottom: 1.5rem;
  }

  @media(min-width: $SCREEN_TABLET) {
    flex-direction:  row;
  }

  img {
    height: 3.75rem;
   
    @media(min-width: $SCREEN_TABLET) {
      flex-direction:  row;
      margin-right: 4rem;
    }
    
    @media(min-width: $SCREEN_NEW_DESKTOP) {
      height: 3.75rem;
    }
  }

  span {
    border-radius:    .75rem;
    padding:          .25rem .625rem .5rem;
    font-size:        .875rem;
    background-color: $GREEN_OPAQUE;
    color:            $MED_GREY;
  }

  .brandText {
    display: flex;
    flex-direction: column;

    max-width: 75%;
    margin-left: 15px;

    @media(min-width: $SCREEN_TABLET) {
      max-width: 50%;
    }
    
    
    h4 {
      
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5em;
      text-align: center;

      @media(min-width: $SCREEN_TABLET) {
        font-size: 1.5rem;
        margin-top: 0;
        text-align: left;
      }
    }

    p {
      font-size: 0.875rem;
      @media(min-width: $SCREEN_TABLET) {
        font-size: 1.125rem;
      }
    }
  }
}




.middleRow {
  display:        flex;
  flex-direction: column;

  border: 0.063rem solid $DIVIDER_GREY;
  box-sizing: border-box;
  border-radius: 1.25rem;
  border-bottom: none;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-top: 2.125rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    flex-direction: row;
  }

  ul {
    flex:        0 1 40%;
    margin-left: 1.5rem;
    margin-top:  1rem;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 2rem 0 2rem;

    @media(min-width: 600px) {
      margin: 1.5rem auto 0;
    }

    @media(min-width: $SCREEN_SM_DESKTOP) {
      margin-left: 2rem;
      margin-top:  0;
      flex:        0 1 50%;
    }

    @media(min-width: $SCREEN_NEW_DESKTOP) {
      margin-left: 3rem;
      flex:        0 1 40%;
      padding-top: 1rem;
    }

    li {
      position:      relative;
      color:         $TAG_LINE;
      margin-bottom: .5rem;
      font-size:     1rem;

      @media(min-width: $SCREEN_SM_DESKTOP) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }

      @media(min-width: $SCREEN_NEW_DESKTOP) {
        // font-size: 0.938rem;
        font-size: 1.1rem;
      }


      &:last-child {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        left:     -1.5rem;
        top:      0;
        width:    1rem;
        height:   1rem;
      }
    }
  }
}


.videoArea {
  flex:           1 0;
  display:        flex;
  flex-direction: column;
  align-items:    center;
  position:       relative;

  .preview {
    height: 12.813rem;
    cursor: pointer;
    max-width: 100%;
    margin-bottom: -0.5rem;

    @media(min-width: $SCREEN_SM_DESKTOP) {
      height: 10rem;
    }

    @media(min-width: $SCREEN_NEW_DESKTOP) {
      height: 16.75rem;
      margin-bottom: -1.5rem;
    }
  }

  .preview:before {
    content:    "";
    display:    block;
    position:   absolute;
    top:        0;
    bottom:     0;
    left:       0;
    right:      0;
    background: rgba(0,255,255, 0.5);
    transition: all .3s linear;
  }

  .svgCont {
    display:         flex;
    justify-content: center;

    svg {
      position:  absolute;
      top:       50%;
      transform: translate(0%, -50%);
      fill:      $TRANSPARENT_GREY;

      @media(min-width: $SCREEN_SM_DESKTOP) {
        top: 8rem;
        left: 8.5rem;
      }

      @media (min-width: $SCREEN_SM_DESKTOP) and (max-width: $SCREEN_NEW_DESKTOP) {
        top: 5rem;
        left: 5rem;
      }
    }
  }



  a {
    cursor:   pointer;
    position: relative;
  }

  .iframe {
    width:      81%;
    height:     100%;
    min-height: 12.5rem;
  }
}

.videoArea a ~ .wireCutter {

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    margin-left: -6rem;
  }
}

.wireCutter {
  display:        flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size:      .75rem;
    text-transform: uppercase;
    color:          $GREEN_JADEITE;
    margin-bottom:  .25rem;
    font-weight:    bold;
  }

  img {
    height: 2rem;
    margin: 0 auto;
  }
}


.bottomArea {
  flex:                    0 1 8rem;
  width:                   100%;
  padding-top:             2.125rem;
  padding-bottom:          1.25rem;
  justify-content:         center;
  border:                  0.063rem solid #CED0D3;
  box-sizing:              border-box;
  border-radius:           1.25rem;
  border-top:              none;
  border-top-left-radius:  0px;
  border-top-right-radius: 0px;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    flex: 0 1 6rem;
  }
  
  @media(min-width: $SCREEN_NEW_DESKTOP) {
    padding-top: 5.125rem;
  }

  .error {
    color:         $RED_ERROR;
    margin-bottom: .5rem;
    font-size:     .875rem;
    text-align:    center;

    @media(min-width: $SCREEN_SM_DESKTOP) {
      text-align: right;
    }
  }


  .btnRow {
    display:         flex;
    flex-direction:  column;
    justify-content: center;

    button, a {
      text-transform: uppercase;
      margin:         0 auto;
      max-width:      17rem;
    }

    button {
      background-color: $CORAL;
      font-size:        1.125rem;
    }

    a {
      padding:         0;
      border:          none;
      margin-top:      0.5rem;
      color:           $LEGAL_GREY;
      font-family:     $HAFFER;
      font-size:       0.75rem;
      text-decoration: underline;
    }
    .accept {
      color: $CORAL;
    }
  }
}

.switchLegal {
  color:         $LEGAL_GREY;
  font-size:     .75rem;
  max-width:     25rem;
  margin:        1rem 0 0 auto;
  padding-right: 5px;
}
