@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/new-form.modules';


.personalSpace {
  @extend .card;
  font-size: 1.125rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1.875rem;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 3.75rem;
    grid-column-gap: 1.875rem;
  }
}

.personalArea {
  font-size: 1rem;
  overflow: hidden;
}
