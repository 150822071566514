//GREYSCALE
$BLACK:          #000;
$MED_GREY:       #333;
$MED_LIGHT_GREY: #434343;
$MAIN_GREY:      #555;
$EVIL_GREY:      #666;
$LEGAL_GREY:     #707070;
$LUCKY_GREY:     #777;
$MIDDLE_GREY:    #999;
$BORDER_GREY:    #D3D3D3;
$AVG_GREY:       #CCC;
$ANOTHER_GREY:   #D8D8D8;
$SLIGHT_WHITE:   #EEE;
$OFF_LIGHT:      #F5F5F5;
$WHITE:          #FFF;
$TRANSPARENT_GREY: #979797;
$ADDON_GREY: #F0F5FA;
$ADDON_BORDER_GREY: #EAECF7;
$LIGHT_BLUE_TOPUP: #DEE5FF;

$LIGHT_RED:      #F3DEDF;
$BRIGHT_RED:     #E12B3D;
$QKN_RED:        #EB0130;
$RED_ICON:       #F74752;

$AZURE_LIGHT:    #2189D4;
$AZURE:          #1273B9;
$AZURE_DEEP:     #0167D6;
$AZURE_DARK:     #0056B3;
$DARK_BLUE:      #164B80;



// COLORS FOR NEW DESIGN
$DIVIDER_GREY:   #CED0D3;
$ACTIVE_TAB:     #EFEFF2;
$TAG_LINE:       #576073;
$BLUE_OPAQUE:    rgba(5, 168, 206, 0.1);
$GREY_PRICE:     #575F73;
$BLUE_DEEP:      #2F3EA8;
// $BLUE_CTA:       #3653D5; ORIGINAL BLUE
$QKN_BLURPLE:    #471CFF; // rebrand blue
$BLUE_ACTION:    #2E3DA8;
$BLUE_INPUT:     #283E9F;
$BLUEBERRY:      #496ED8;
$BLUE_HEADING:   #22324F;
$BLUE_LINK:      #0000ee;
$BLUE_SKY:       rgba(5, 168, 206, 0.8);
$GREEN_OPAQUE:   rgba(2, 205, 171, 0.1);
$GREEN_SEA:      #02CDAB;
$GREY_HEADER:    #F6F7F8;
$GREY_BORDER:    #EBEFF2;
$PURPLE:         #5365B7;
$BLUE_SEA:       #409EDC;
$LIGHT_BLUE_BG:  #ECF4FC;
$LIGHT_BLUE_BRD: #C1DCF7;

// $RED_OPAQUE:     rgba(242, 134, 109, 0.1);
$RED_OPAQUE:     rgba(251, 127, 121, 0.3);
$ORANGE:         #F69E8A;
$RED_SALMON:     #F2866D;
$RED_ERROR:      #C45254;
$RED_CORAL:      #FB7F79;
$PURPLE_ACTIVE:  #263FBE;
$PURPLE_TOGGLE:  #B5C0F9;
$GENESYS_YELLOW: #F8EDD0;

$PURPLE_ACCENT:  #9882C4;
$CANCEL_YELLOW:  #F9EFCE;
$GREEN_LIGHT:    #80BE57;
$GREEN_DARK:     #53914F;
$GREEN_JADEITE:  #62B59E;

$CORAL:          #F74852;

//BM Tier Colors
$BM_LITE:   #F4C93A;
$BM_BASIC:  #02CDAB;
$BM_PLUS:   #8FE1F7;
$BM_VOL50:  #05A8CE;
$BM_VOL100: #5365B7;
$BM_VOL200: #2F3EA8;