/*
  Initialization styles, resets, and normalizations
*/

@import 'sass/mixins/add-font';


@include font('OpenSans', '../../assets/fonts/Open_Sans/OpenSans-Regular');
@include font('Poppins', '../../assets/fonts/Poppins/Poppins-Regular');
@include font('PoppinsBold', '../../assets/fonts/Poppins/Poppins-Bold');
@include font('PoppinsSemiBold', '../../assets/fonts/Poppins/Poppins-SemiBold');
@include font('circularBlack', '../../assets/fonts/Circular/circular-black');
@include font('circularBold', '../../assets/fonts/Circular/circular-bold');
@include font('circularBook', '../../assets/fonts/Circular/circular-book');
@include font('circularMedium', '../../assets/fonts/Circular/circular-medium');
@import 'sass/libs/normalize';
@import 'sass/resets/element-resets.module';
@import 'sass/resets/braintree-dropin-ui.module';


@font-face {
  font-family: 'Haffer';
  src: url('./assets/fonts/Haffer/Haffer-Regular.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Haffer';
  src: url('./assets/fonts/Haffer/Haffer-RegularItalic.woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Haffer';
  src: url('./assets/fonts/Haffer/Haffer-SemiBold.woff2');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'Haffer';
  src: url('./assets/fonts/Haffer/Haffer-SemiBoldItalic.woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'HafferBold';
  src: url('./assets/fonts/Haffer/Haffer-SemiBold.woff2');
  font-weight: 600;
  font-style: bold;
}

:global(#root) {
  flex:           1 0;
  display:        flex;
  flex-direction: column;
  width:          100%;
}
