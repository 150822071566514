@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';

.newTextField {
  position: relative;
  font-family: $HAFFER;

  label {
    font-size: .75rem;
    color: rgba(0,0,0,0.6);
    position: absolute;
    background-color: $WHITE;
    top: -.5rem;
    left: 1rem;
    padding: 0 .5rem;
    &.hasError {color: $RED_ERROR;}
  }

  input {
    border: .0625rem solid rgba(0,0,0,0.38);
    border-radius: .75rem;
    outline: none;
    width: 100%;
    font-size: .875rem;
    padding: .5rem;
    line-height: 1.125rem;

    @media(min-width: $SCREEN_TABLET) {
      font-size: 1rem;
      padding: .625rem;
      line-height: 1.25rem;
    }
    @media(min-width: $SCREEN_SM_DESKTOP) {
      font-size: 1.125rem;
      padding: 1rem 1.5rem;
      line-height: 1.5rem;
    }
    &.hasError {
      border: .0625rem solid $RED_ERROR;
    }
    &:disabled {
      cursor: not-allowed;
    }

    &:focus {
      border: .0125rem solid $BLUE_INPUT;
      padding: .5rem;

      @media(min-width: $SCREEN_TABLET) {
        padding: .625rem;
      }
      @media(min-width: $SCREEN_SM_DESKTOP) {
        padding: 1rem 1.5rem;
      }
    }
  }

  .textFieldError {
    color: $RED_ERROR;
    font-size: .75rem;
    height: .75rem;

    @media(min-width: $SCREEN_TABLET) {
      font-size: .875rem;
      height: .875rem;
    }
  }
}
