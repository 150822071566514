@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import '../info-area.module';

.acmeCard {
  composes: card;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: .75rem;
    background-color: $QKN_BLURPLE;
    width: 100%;
    left: 0;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }
}

.name {
  font-weight: 600;
  margin-bottom: .5rem;
}

.tag {
  margin-bottom: 1.375rem;
}

.cancelBox {
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.acmeInfo {
  display:        flex;
  flex-direction: column;
  padding:        1.9375rem .9375rem 1.375rem;
  position:       relative;
  @media (min-width: $SCREEN_TABLET) {
    padding: 0 0 1.375rem;
  }
  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex-direction: row;
  }
}

.leftSide {
  text-align:    left;
  margin-bottom: .3125rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex: 1 0;
  }

  .range {
    margin-left: 2.75rem;
  }
}


.rightSide {
  text-align:  right;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex:        1;
  }
}

.priceRow {
  text-align: left;
  margin:     .3125rem 0 .3125rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin:     0 0 0 auto;

    & span:first-child {
      font-size: 1.3125rem;
    }

    & span:nth-child(2) {
      font-size: .875rem;
    }

    & span:nth-child(3) {
      font-size: .625rem;
    }
  }
}

.remainingTime {
  margin-bottom: .5rem;
  font-family: $HAFFER;
}

.freeTrial {
  font-family: $HAFFER;
  font-size: 1rem;
  color: $TAG_LINE;
  margin-bottom: .5rem;
}

.star {
  display: none;
  position: absolute;
  right:  0;

  @media (min-width: $SCREEN_TABLET) {
    display: block;
    bottom: 0;
    height: 8rem;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    height: 6rem;
  }
}


.happyStar {
  composes: star;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    bottom: .5rem;
  }
}

.sadStar {
  composes: star;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    bottom: -.5rem;
  }
}

.legal {
  font-size: .85rem;
  margin: .5rem 0 0rem;
  font-family: $HAFFER;

  &.priceWrn {
    width: 75%;
  }
}
