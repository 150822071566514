
@import 'bm-sub-classes.module';
@import 'src/sass/buttons.module';
@import '../tile.module';

.bmExpiry {
  margin: 0;
}

.topQuota {
  margin-top: 1rem;
}

.renewalArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.renewalLabel {
  grid-area:        renewalLabel;
  font-family:      $HAFFER_BOLD;
  font-size:        .75rem;
  color:            $BLUE_HEADING;
  background-color: $ACTIVE_TAB;
  border-radius:    .375rem;
  padding:          .25rem .625rem;
  margin:           .75rem auto .625rem 0;
  display:          block;
}


.renewalName {
  grid-area:   renewalName;
  font-family: $HAFFER_BOLD;
  font-size:   1.125rem;
  color:       $BLUE_HEADING;
  margin:      .5rem auto 0 0;
}


.dash {
  composes: dash;
  margin-top: 1.875rem;
}

.spaceDash {
  padding-bottom: 1.5rem;
}

.legal {
  font-size: .75rem;
  // margin: .5rem 0;
  font-family: $HAFFER;
}

.prevPlan {
  text-align: center;
  margin-bottom: 1.25rem;
}

.benefit {
  font-size: .75rem;
  color:     $TAG_LINE;
  margin:    1.25rem 0;
}

.expiredEntitlements {
  width: 100%;

  .payCounts {
    font-family: "OpenSans", sans-serif;
    color: #576073;
    font-size: .75rem;
    line-height: 1.0625rem;
    margin-top: 1rem;
  }
}