@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.contentMessage {
  padding:       1.25rem 1.625rem;
  font-size:     1rem;
  border-radius: .75rem;
  margin-bottom: 1.5rem;
  font-family:   $HAFFER;
  line-height:   1.375rem;
  border:        0.125rem solid #F74852;


  .title {
    font-family: $HAFFER_BOLD;
    // margin-bottom: 1rem;
    margin-bottom: 0.875rem;
  }

  &.success {
    background-color: $GREEN_OPAQUE;
    border:           solid .0625rem $GREEN_SEA;
  }

  &.info {
    background-color: $BLUE_OPAQUE;
    border:           solid .0625rem $BLUE_SKY;
  }

  &.error {
    background-color: $RED_OPAQUE;
    // border:           solid .0625rem $RED_ERROR;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  a {
    margin-left: .25rem;
    color:       $QKN_BLURPLE;
  }
}
