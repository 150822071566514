@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import '../info-area.module';
// @import '../../../pages/subscriptions/tile.module.scss';


.prodName {
  font-family:   $HAFFER_BOLD;
  // margin-bottom: .5rem;
}


.tagLine {
  font-family:   $HAFFER;
  // margin-bottom: 1.375rem;
}


.bmInfo {
  display:        flex;
  flex-direction: column;
  // padding:        1.9375rem .9375rem 1.375rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    // padding:         0;
    flex-direction:  row;
    justify-content: space-between;
    margin-bottom:   0.75rem;
  }

  .infoLeft {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}

.infoRight {
  text-align: right;
}


.topArea {
  grid-template-columns: 2.25rem 1fr;
  grid-column-gap:       .625rem;
  margin-bottom:         .625rem;
  display:               grid;
  grid-template-areas:
    'prodImg prodName'
    'prodImg prodTag';

  img {
    height: 2.25rem;
  }
}


.activeTopArea {
  margin-bottom: 1.25rem;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin-bottom: 0;
  }

  .effectiveRange {
    margin: .625rem 0 0 2.875rem;
  }
}


.renewalLabel {
  font-family:      $HAFFER_BOLD;
  font-size:        .75rem;
  color:            $BLUE_HEADING;
  background-color: $ACTIVE_TAB;
  border-radius:    .375rem;
  padding:          .25rem .625rem;
  margin:           0;
}


.renewalName {
  font-family: $HAFFER;
  font-size:   .625rem;
  color:       $TAG_LINE;
  margin-left: .5rem;
  margin-top:  .25rem;
}

.priceArea {
  display: flex;
  align-items: center;
}

.expiryRow {
  margin: 0;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin: .3125rem 0 0;
  }
}

.bpCTA {
  font-family: $HAFFER_BOLD;
  color: $QKN_BLURPLE;
  font-size: .8125rem;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.benefit {
  font-size: .75rem;
}

.dash {
  display: block;
  width: 100%;
  background-color: $DIVIDER_GREY;
  height: .0625rem;
  margin: 0 0 0.75rem;

  &.miniSub {
    margin-top: 0.75rem;
  }
}