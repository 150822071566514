@import 'src/sass/buttons.module';
@import '../../new-modal.module';

.surveyWrapper {
  margin: 0 auto 1.875rem;
  max-width: 35rem;
}

.surveyCloseBtn {
  @extend .contentResetBtn;
  margin: 0 0 0 auto;
  display: block;
}
