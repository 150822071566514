@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  button {
    background-color: transparent;
    border: 0;
    margin-left: .625rem;
    padding: .25rem .6875rem;
    border-radius: .5rem;
    font-family: $HAFFER_BOLD;
    outline: none;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &.selected {
      background-color: $ACTIVE_TAB;
      border: solid .0625rem $MED_GREY;
    }
  }
}
