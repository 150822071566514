@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';

.history,
.paymentsContain {
  background-color: $WHITE;
  padding: 1.875rem;
  border-radius: 0.8125rem;
  box-shadow: 0rem 0.375rem 0.75rem 0rem rgba(0, 0, 0, 0.16), 0rem -0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.05);
}

.paymentsContain {
  margin-bottom: 1.875rem;

  .titleContain {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

  .paymentsRowContain {
    display: flex;
    flex-direction: column;
  }
}

.paymentAlert {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: $RED_OPAQUE;
  border: solid .0625rem $CORAL;
  border-radius: .5rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
  font-size: .8125rem;
  line-height: 1.125rem;

  .messageTitle {
    font-size: 1.0625rem;
    font-weight: 600;
    ;
  }
}