@import 'src/sass/themes/sizes';
@import 'src/sass/themes/fonts';
@import 'src/sass/buttons.module';
@import '../new-modal.module';

.description {
  margin-bottom: 1rem;
  font-family: $HAFFER;
}

.offer {
  font-family: $HAFFER_BOLD;
  font-weight: bold;
  margin-bottom: 1rem;
}

.list {
  list-style: inside;
  margin: 0 0 2rem 1rem;

  li {
    font-family: $HAFFER;
    margin-bottom: .5rem;

    a {
      margin-left: .25rem;
    }
  }
}

.dbTermsContain {
  text-align: center;
  background-color: rgba(5, 168, 206, 0.1);
  border: solid 0.0625rem rgba(5, 168, 206, 0.8);
  border-radius: 0.75rem;
  padding: 1.25rem 1.625rem;
  font-family: $HAFFER;
  line-height: 1.375rem;
  margin-bottom: 1.5rem;

  .termsLink {
    color: $BLUE_LINK;
    border: none;
    background-color: unset;
    text-decoration: underline;
    cursor: pointer;
  }

  .acceptBtn {
    background-color: $QKN_BLURPLE;
    border-color: $QKN_BLURPLE;
    color: $WHITE;
    border-style: solid;
    border-radius: 1.25rem;
    padding: 8px 15px;
    margin-top: 12px;
  }
}

.dbTermsHeader {
  position: sticky;
}

.termsStickyFt {
  position: sticky;
  bottom: 0;
  background-color: $WHITE;
  padding: 10px 0px;

  button {
    padding: 0px 45px;

    @media(min-width: $SCREEN_TABLET) {
      padding: 0px 100px;
    }
  }
}

.noOffer {
  font-family: $HAFFER;
  margin-bottom: 2rem;
}

.termsContent {
  padding: 0px 45px;

  @media(min-width: $SCREEN_TABLET) {
    padding: 0px 100px;
  }

  h3 {
    padding: 15px 0px;
  }

  p {
    padding-bottom: 10px;
  }
}

.dbCloseBtn {
  composes: contentResetBtn;
  padding: 0;
}
