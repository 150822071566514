@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';

.prodName {
  font-size:   1.125rem;
  font-family: $HAFFER;
  color:       $BLUE_HEADING;

  // &.hasArOff {
    // max-width: calc(100% - 6rem); // DUE TO CANCELLED BOX
    // @media (min-width: $SCREEN_SM_DESKTOP) {
      // max-width: calc(100% - 9rem); // DUE TO CANCELLED BOX
    // }
  // }

  .qknTheme {
    color: $QKN_RED;
  }

  .simpTheme {
    color: $QKN_BLURPLE;
  }
}
