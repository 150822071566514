@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';

.boxArea {
  display:        flex;
  flex-wrap:      wrap;
  align-items:    center;
  flex-direction: column;
  z-index: 0;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    align-items: baseline;
  }
}

.simpArea {
  flex:       48% 0;
  width:      100%;
  // max-width:  36rem;
  align-self: flex-start;
  margin:     0 auto;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin: unset;
  }
}
