@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.priceArea {
  font-family: $HAFFER;
  margin-left: 5rem;
  display: flex;
}

.price {
  display: flex;
  align-items: center;

  &::before{
    content: '';
    flex: 1 0 10px;
    width: 3.4rem;
    border-top: 2px solid $ORANGE;
    z-index: 1;
  }

  .text{
    font-weight: normal;
    margin-left: -3.333rem;
    color: $TAG_LINE;
  }
}

.discount {
  color: white;
  margin-left: 0.25rem;
  background-color: $ORANGE;
  padding: 0px 5px;
  border-radius: 20px;
  position: relative;
  width: 4rem;
  font-size: 0.85rem;
  text-align: center;
  line-height: 1.15rem;
}

.dash {
  color: $DIVIDER_GREY;
  font-size: 1.375rem;
  margin: 0 .25rem;
}

.interval {
  color: $TAG_LINE;
  font-size: 1rem;
}
