@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/buttons.module';
@import '../tile.module';


.trialHeading {
  font-family:   $HAFFER;
  font-size:     1rem;
  color:         $TAG_LINE;
  margin-top:    1.5rem;
  margin-bottom: 1rem;
}


.remaining {
  font-family:   $HAFFER;
  margin-bottom: .5rem;
}

.trialRemaining {
  font-family:   $HAFFER;
  margin-bottom: 1.5rem;
}

.star {
  display: none;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display: block;
    position: absolute;
    bottom: 4rem;
    right: 0.75rem;
    height: 8rem;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    display: none;
  }
  @media (min-width: $SCREEN_WIDE) {
    display: block;
  }
}

.legal {
  font-size: .75rem;
  margin: .5rem 0 -1rem;
  font-family: $HAFFER;

  &.priceWrn {
    width: 75%;
  }
}
