@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import '../../new-modal.module';

.paymentForm {

  h3 {
    font-size:     1rem;
    font-family:   $HAFFER;
    font-weight:   normal;
    line-height:   1.3125rem;
    color:         $MED_GREY;
    margin-bottom: 1.25rem;
    text-align:    center;

    @media (min-width: $SCREEN_NEW_DESKTOP) {
      line-height:   1.5625rem;
      margin-bottom: 3.125rem;
    }
  }
}

.btnRow {
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: $SCREEN_TABLET) {
    flex-direction: row;
    align-items: center;
  }
}

.pushRight {
  margin: 0 0 0 auto;
}

.checkbox {
  margin-bottom: 1.25rem;

  label {
    font-family: $HAFFER;
  }

  @media (min-width: $SCREEN_TABLET) {
    margin-bottom: 0;
  }
}
