html, body {
  box-sizing:     border-box;
  height:         100%;
  width:          100%;
  margin:         0;
  padding:        0;
  position:       relative;
  display:        flex;
  flex-direction: column;
  font-family:    'Haffer', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust:none;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  border-radius: .25rem;
}


h1, h2, h3, h4, h5, h6, p, label {
  margin:                 0;
  padding:                0;
  -webkit-font-smoothing: antialiased;
}


ul {
  list-style: none;
  padding:    0;
  margin:     0;
}
