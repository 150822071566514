@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import 'src/sass/layouts/main.module';
@import 'src/sass/themes/pallet-colors';


$BLOCK_COLOR: $SLIGHT_WHITE;


.skelPageLayout {
  display:          flex;
  flex-direction:   column;
  height:           100%;
  background-color: $OFF_LIGHT;
  min-width:        20rem;
}


.skelMainArea {
  @extend .mainLayout;
  align-items:    stretch;
  height:         100%;
  padding-bottom: 5rem;
  flex:           1 0;
  margin-top:     2.25rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-top: 5rem;
    max-width:  75rem;
  }
}


.skelNavWrapper {
  border-right: .0625rem solid $BLOCK_COLOR;
}


.skelGreetWrapper {
  display: flex;
  width:   85%;
  margin:  2rem auto 0;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-top: 4.0625rem;
    max-width:  1200rem;
    width:      100%;
  }
}


.skelGreetLeft {
  flex: 0 1;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    flex-basis: 17.5rem;
  }
}


.skelGreetRight {
  background-color: $BLOCK_COLOR;
  height:           1.6875rem;
  flex:             1 0 auto;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin:    0 auto;
    max-width: 80%;
  }

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    margin:    0 21.25rem 0 0;
    height:    2.875rem;
    max-width: unset;
  }
}


.skelHeadingText {
  composes:         headingText;
  background-color: $BLOCK_COLOR;
  height:           1.6875rem;
  width:            8.75rem;
}


.skelNavList {
  margin-top: 3.125rem;
}


.skelNavItem {
  background-color: $BLOCK_COLOR;
  height:           1.625rem;
  margin:           .75rem 1.3125rem 1.875rem;
}


.skelBoxArea {
  display:               grid;
  grid-template-columns: 1fr;
  grid-row-gap:          1.25rem;

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    margin-top:            6.25rem;
    grid-template-columns: minmax(0, 25rem) minmax(0, 25rem);
    grid-template-rows:    1fr 1fr;
    grid-column-gap:       2rem;
    grid-row-gap:          2rem;
  }
}


.skelBox {
  border-radius:    .8125rem;
  height:           21.0625rem;
  background-color: $BLOCK_COLOR;
}
