@import 'src/sass/themes/colors';
@import '../tile.module';
@import 'src/sass/buttons.module';

.addonMinTile {
  composes: tile;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border-radius: 12px;
  gap: 0.5rem;
  padding: 0.75rem;
  box-shadow: none;
  background-color: $ADDON_GREY;
  border: 0.0625rem solid $ADDON_BORDER_GREY;
  margin: 0;
  height: max-content;

  .qknTheme {
    color: $QKN_RED;
  }
}

.bpCTA {
  font-family: $HAFFER_BOLD;
  color: $QKN_BLURPLE;
  font-size: .8125rem;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}