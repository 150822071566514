@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';


.qknCPWrapper {
  composes: modalBody;
  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding: 1.1875rem 1.875rem 2.1875rem;
  }

  @media(min-width: $SCREEN_WIDE) {
    padding: 3.4375rem 4.375rem;
  }
}


.toggle {
  display:         flex;
  font-family:     $HAFFER;
  color:           $TAG_LINE;
  font-size:       1.125rem;
  justify-content: space-between;
  width:           12rem;
  align-items:     center;
  margin:          0 auto 1.875rem;
}


.label {
  font-family:   $HAFFER_BOLD;
  font-size:     1.125rem;
  color:         $TAG_LINE;
  margin-bottom: 1.5rem;
}


.name {
  font-family:   $HAFFER_BOLD;
  margin-bottom: .5rem;
}


.tag {
  font-size:   .75rem;
  font-family: $HAFFER;
  color:       $TAG_LINE;
}


.currentPlan {
  position:       relative;
  color:          $BLUE_HEADING;
  box-shadow:     0 .5rem 1rem .0625rem rgba(0, 0, 0, 0.16), 0 -.0625px .5px 0 rgba(0, 0, 0, 0.05);
  border-radius:  .875rem;
  margin-bottom:  1.25rem;
  padding:        2rem 1.25rem 1.75rem;
  // display:        flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 10rem;
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas:
      'currentLeft currentRight'
      'cancelDwngrade cancelDwngrade';

  @media (min-width: $SCREEN_TABLET) {
    flex-direction: row;
  }

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.standard):before,
  &:global(.plus):before,
  &:global(.extra):before,
  &:global(.volume50):before,
  &:global(.volume100):before,
  &:global(.volume200):before,
  &:global(.starter):before,
  &:global(.deluxe):before,
  &:global(.premier):before,
  &:global(.hab):before,
  &:global(.hab-rpm):before,
  &:global(.cash-manager):before {
    background-color: $QKN_RED;
  }

  &:global(.acme-basic):before,
  &:global(.acme):before {
    background-color: $QKN_BLURPLE;
  }


  .currentLeft {
    grid-area: currentLeft;
    // flex: 1 0;
  }

  .currentPrice {
    flex:       1 0;
    text-align: left;
    margin-top: 0.5rem;
    // margin:     .75rem 0 1rem;

    @media (min-width: $SCREEN_TABLET) {
      // margin:     0 0 3rem;
      // text-align: center;
      padding-right: 0.625rem;
    }
  }

  .currentRight {
    grid-area: currentRight;
    // flex: 0 1 2.25rem;

    @media (min-width: $SCREEN_TABLET) {
      // flex: 0 1 9.25rem;
    }
  }

  .cancelDgradeBox {
    margin-top: 1.5rem;
    grid-area: cancelDwngrade;
  }
}


.inactiveBtn {
  font-size:        .875rem;
  font-family:      $HAFFER_BOLD;
  outline:          none;
  border-width:     .125rem;
  border-style:     solid;
  border-radius:    1.25rem;
  cursor:           pointer;
  grid-area:        selectBtn;
  width:            100%;
  height:           2.25rem;
  background-color: $WHITE;
  border-color:     $TAG_LINE;
  color:            $TAG_LINE;

  &:disabled {
    cursor: not-allowed;
  }
}


.extendPlan {
  composes:    linkBtn;
  color:       $QKN_BLURPLE;
  font-family: $HAFFER_BOLD;
  font-size:   .75rem;
  margin:      1rem 0 0;
  display:     block;
  text-align:  center;
}


.dash {
  margin:           2.5rem 0 2rem;
  height:           .0625rem;
  background-color: $DIVIDER_GREY;
}


.optionArea {
  display:               grid;
  grid-template-columns: 1fr;
  grid-row-gap:          1rem;
  grid-column-gap:       1rem;

  @media (min-width: $SCREEN_TABLET) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display:         flex;
    justify-content: space-between;
  }
}
