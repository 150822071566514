@import 'qob-sub-classes.module';
@import '../../../../sass/buttons.module';
@import '../../../../sass/themes/colors';
@import '../../../../sass/themes/fonts';

.primaryHighlight {
  color: $QKN_RED;
}

.qknTagLine {
  font-size: 0.8125rem;
}

.cardTop {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}


.price{
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;

  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.featureList {
  margin-top: 0.75rem;
  font-family: $HAFFER;
  font-size: .8125rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  li {
    position: relative;
    color: $TAG_LINE;
    line-height: 1.125rem;
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

.qobNote {
  font-family: $HAFFER;
  font-size: .8125rem;
  color: $TAG_LINE;
  margin-bottom: 1.25rem;
  margin: 0.75rem 0;
}

.dash {
  margin-bottom: 0.75rem;
}

.contentCTABtn {
  padding: 0.75rem 1.25rem;
}