@import 'src/sass/themes/pallet-colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.contentResetBtn {
  color:            $QKN_BLURPLE;
  font-family:      $HAFFER;
  font-size:        1.125rem;
  border:           0;
  outline:          none;
  background-color: transparent;
  cursor:           pointer;
}


.contentCTABtn {
  color:            $WHITE;
  font-family:      $HAFFER_BOLD;
  font-size:        .875rem;
  background-color: $QKN_BLURPLE;
  border-radius:    1.125rem;
  border:           0;
  outline:          none;
  padding:          .625rem 1.9375rem;
  cursor:           pointer;
  text-decoration:  none;

  &:disabled {
    border:           solid .125rem $QKN_BLURPLE;
    background-color: $WHITE;
    color:            $QKN_BLURPLE;
    cursor:           not-allowed;
  }
}


.outlineCTABtn {
  color:            $QKN_BLURPLE;
  background-color: $WHITE;
  font-family:      $HAFFER_BOLD;
  font-size:        .875rem;
  border-radius:    1.125rem;
  border:           .0625rem solid $QKN_BLURPLE;
  outline:          none;
  padding:          .625rem 1.9375rem;
  cursor:           pointer;
  text-decoration:  none;

  &:disabled {
    border:           solid .125rem $QKN_BLURPLE;
    background-color: $WHITE;
    color:            $QKN_BLURPLE;
    cursor:           not-allowed;
  }
}


.linkBtn {
  font-size:       .875rem;
  background:      transparent;
  border:          0;
  font-family:     $HAFFER_BOLD;
  color:           $QKN_BLURPLE;
  outline:         none;
  cursor:          pointer;
  text-decoration: none;

  &:disabled {
    color:  $LUCKY_GREY;
    cursor: not-allowed;
  }
}
