
@import 'bm-sub-classes.module';
@import '../../../../sass/buttons.module';
@import '../../../../sass/themes/colors';
@import '../../../../sass/themes/fonts';

.primaryHighlight {
  color: $QKN_RED;
}

.cardTop {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.featureList {
  margin: 0.75rem 0;
  font-family: $HAFFER;
  font-size: .8125rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  li {
    position: relative;
    color: $TAG_LINE;
    line-height: 1.125rem;
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

.dash {
  margin-bottom: 0.75rem;
}

.contentCTABtn {
  padding: 0.75rem 1.25rem;
}