@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.priceArea {
  font-family: $HAFFER;
  min-height: 1rem;
}

.price {
  font-size: 1.625rem;
  color: $TAG_LINE;
}

.dash {
  color: $DIVIDER_GREY;
  font-size: 1.375rem;
  margin: 0 .25rem;
}

.interval {
  color: $TAG_LINE;
  font-size: 1rem;
}
