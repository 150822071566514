@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';


.cardWrapper {
  display: none;

  &.show {
    display: block;
  }
}


.cardRow {
  display:       none;
  margin-bottom: 2.5rem;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display: flex;
  }

  img {
    height:       2.25rem;
    margin-right: .75rem;
    @media (min-width: $SCREEN_NEW_DESKTOP) {
      margin-right: 1.5rem;
      height:       3rem;
    }
  }
}


.inputArea {
  display:               grid;
  grid-template-columns: 1fr;
  grid-row-gap:          .9375rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       1.25rem;
  }
}


.inputField {
  width:    100%;
  padding:  0;
  position: relative;

  label {
    font-size:        .75rem;
    color:            rgba(0, 0, 0, 0.6);
    position:         absolute;
    background-color: $WHITE;
    top:              -.5rem;
    left:             1rem;
    padding:          0 .5rem;

    & ~ div {
      border-radius: .75rem;
      border:        .0625rem solid rgba(0, 0, 0, 0.38);
      height:        2.125rem;
      padding:       .5rem;

      @media(min-width: $SCREEN_TABLET) {
        padding: .625rem;
        height:  2.5rem;
      }
      @media (min-width: $SCREEN_SM_DESKTOP) {
        height:  3.375rem;
        padding: 1rem 1.5rem;
      }
    }
  }

  .inputError {
    border: .0625rem solid $RED_ERROR;
  }

  :global(.braintree-hosted-fields-focused) {
    border:  .125rem solid $BLUE_INPUT;
    padding: .4375rem;

    @media(min-width: $SCREEN_TABLET) {
      padding: .5625rem;
    }
    @media(min-width: $SCREEN_SM_DESKTOP) {
      padding: .9375rem 1.4375rem;
    }
  }


  .error {
    font-size: .75rem;
    height:    .9375rem;
    color:     $RED_ERROR;
    @media(min-width: $SCREEN_SM_DESKTOP) {
      font-size: .875rem;
    }
  }
}
