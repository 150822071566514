@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';


.wrapper {
  max-width: 45.3125rem;
}


.heading {
  max-width:   36.5625rem;
  font-size:   .875rem;
  text-align:  center;
  font-family: $HAFFER;
  margin:      0 auto;

  @media (min-width: $SCREEN_TABLET) {
    text-align: left;
    font-size:  1rem;
  }
}


.product {
  display:               grid;
  border-radius:         .8125rem;
  margin:                0 auto;
  padding:               2.25rem 1.25rem 1.5rem;
  position:              relative;
  box-shadow:            0 .375rem .75rem .0625rem rgba(0, 0, 0, 0.24);
  max-width:             36.5625rem;
  grid-column-gap:       1.25rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    'product'
    'tagLine'
    'price'
    'button';

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    background-color:        $QKN_BLURPLE;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  @media (min-width: $SCREEN_TABLET) {
    grid-template-columns: 1fr 9rem 10.75rem;
    grid-template-areas:
    'product price button'
    'tagLine price button'
  }

  img {
    grid-area: img;
    height:    3.4375rem;
    @media (min-width: $SCREEN_SM_DESKTOP) {
      height: 2.8125rem;
    }
  }

  button {
    grid-area:   button;
    margin-top:  .9375rem;
    font-size:   .75rem;
    font-family: $HAFFER;
    padding:     0;
    height:      2.25rem;


    @media (min-width: $SCREEN_TABLET) {
      margin-top: unset;
      align-self: center;
    }
  }
}


.productName {
  grid-area:     product;
  margin-bottom: .5rem;
}


.tagLine {
  grid-area:     tagLine;
  margin-bottom: .5rem;
}


.cpDash {
  margin:           2rem 0;
  height:           .0625rem;
  background-color: $DIVIDER_GREY;
}


.priceWrapper {
  grid-area:   price;
  font-family: $HAFFER;
  align-items: flex-start;
  display:     inline-block;
  position:    relative;
  align-self:  flex-start;
  text-align:  left;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    align-self: flex-start;
  }
}
