@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.heading {
  color: $MED_GREY;
  font-family: $HAFFER_BOLD;
  font-size: 1rem;
  text-align: center;

  &:first-child {
    text-align: left;
  }

  @media (min-width: $SCREEN_TABLET) {
    text-align: left;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
