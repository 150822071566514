@import 'src/sass/themes/sizes';
@import '../tile.module';

.subTileMini {
  padding: 1.125rem;
  border: 1px solid $ADDON_BORDER_GREY;
  border-radius: 20px;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    min-width: 17.125rem;
    padding: 1.875rem;
  }

  .subTileMiniHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;

    .productExpiry {
      width: fit-content;
    }
  }

  .miniSubName {
    font-size: 1.0625rem;
    font-weight: 600;
  }

  .addonTitle {
    font-weight: 600;
    font-size: 0.875rem;
    padding-bottom: 0.75rem;
  }

  .addonContain {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}