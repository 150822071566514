@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';

.paymentArea {
  display: grid;
  align-items: center;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  
  img {
    grid-area: img;
  }

  span {
    grid-area: data;
    color: $MED_GREY;
    font-family: $HAFFER;
    font-size: .75rem;

    @media (min-width: $SCREEN_TABLET) {
      font-size: 1rem;
    }
  }

  button {
    grid-area: btn;
  }
}

.nameLabel{
  font-size: 1rem;
}

.ppPaymentArea {
  @extend .paymentArea;
  margin-bottom: 3.125rem;
  grid-template-areas:
    'img'
    'data'
    'btn';

  @media (min-width: $SCREEN_TABLET) {
    justify-content: flex-start;
    grid-template-areas: 'img data btn';
  }


  img {
    height: 1.25rem;
    margin: 0 auto;

    @media (min-width: $SCREEN_TABLET) {
      margin: 0;
    }
  }

  span {
    text-align: center;
    @media (min-width: $SCREEN_TABLET) {
      text-align: left;
    }
  }
}

.ccPaymentArea {
  @extend .paymentArea;
  margin-bottom: 3rem;
  justify-content: center;
  grid-template-areas:
    'img data'
    'btn btn';

  @media (min-width: $SCREEN_TABLET) {
    justify-content: flex-start;
    grid-template-areas: 'img data btn';
  }


  img {
    height: 1.875rem;
  }

    
  &.tilePay {
    grid-template-areas: 
    'img data btn btn';
    margin-bottom: 1rem;

    button {
      border: 1px solid $MED_GREY;
      box-sizing: border-box;
      border-radius: 12px;
      font-size: 10px;
      padding: 5px 10px;
      color: $MED_GREY;
    }
  }
}

