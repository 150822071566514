@import 'src/sass/themes/new-form.modules';

.securityFormWrapper {
  composes: card;
  font-size: 1.125rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1.875rem;

  @media(min-width: $SCREEN_NEW_DESKTOP) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1.875rem;
    grid-row-gap: 3.75rem;
  }
}

.signOutBtn {
  @extend .contentAction;
  text-align: left;
  margin-top: 0;
}
