@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.modalBody {
  @media (min-width: $SCREEN_SM_DESKTOP) {
    padding: 1.875rem 3.125rem;
  }
}

.pointsContain {
  background-color: $ADDON_GREY !important;
  padding: 1.25rem 1.625rem;
  border: 0.125rem solid #F74852;
  font-family: "Haffer", sans-serif;
  border-radius: .75rem;
}

.pointsTitle {
  margin-bottom: 0.875rem;
  color: $BLUE_HEADING;
}

.pointsSubline {
  margin-bottom: 1.5rem;

  span,
  .downgradeProdName {
    font-family: "Haffer", sans-serif;
    color: $BLACK;
    font-size: 0.8125rem;
  }
}

.points {
  font-size: 0.8125rem;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  margin-bottom: 0.625rem;

  @media (min-width: $SCREEN_TABLET) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $SCREEN_SM_DESKTOP) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.pointWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  
  span {
    max-width: 12.0625rem;
  }
}

.bulletImg {
  width: 18px;
  height: auto;
}

.windOnly {
  font-size: 0.8125rem;
}

.productCard {
  margin-top: 1.25rem;
}

.btnRow {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  justify-content: center;

  @media (min-width: $SCREEN_TABLET) {
    flex-direction: row;
  }

  .contentCTABtn {
    font-size: 1.0625rem;
  }
  .keepCurrentTier {
    background-color: $WHITE;
    color: $QKN_BLURPLE;
    border: 0.125rem solid $ADDON_BORDER_GREY
  }
}