@import '../tile.module';
@import 'src/sass/themes/colors';
@import 'src/sass/buttons.module';


.trialHeading {
  font-family:   $HAFFER;
  font-size:     1rem;
  color:         $TAG_LINE;
  margin-bottom: 1rem;
}

.trialRemaining {
  font-family:   $HAFFER;
}

.expiry {
  margin: 1rem 0 0;
}

.mobileDash {
  composes:   dash;
  margin-top: 2rem;
}


.appleIcon img {
  display:       block;
  height:        2.5rem;
  margin-bottom: .5rem;
}


.googleIcon img {
  display:       block;
  height:        2.5rem;
  margin-bottom: .5rem;
}


.subText {
  margin-top:  .625rem;
  font-size:   .625rem;
  font-family: $HAFFER;
  color:       $TAG_LINE;
}
