@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;

    src: url($font-file+'.woff') format('woff'),
         url($font-file+'.woff2') format('woff2'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
