@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.topUpWrapper {
  grid-area:   toggle;
  color:       $TAG_LINE;
  font-family: $HAFFER;
  font-size:   .75rem;
  position:    relative;
  display:     flex;
  margin:      0 auto 2rem;

  @media (min-width: $SCREEN_TABLET) {
    margin-bottom: 0;
  }
  @media (min-width: $SCREEN_SM_DESKTOP) {
    align-items:   center;
    margin-right:  0;
    margin-bottom: 0;
  }

  .text {
    margin-right: 1.25rem;
  }

  &.tileToggle {
    margin-bottom: 1.25rem;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #ECF4FC;
    // padding: 0rem 0.9375rem 0.625rem;
    padding: 0.5rem;
    border-radius: 8px;
    margin-right: auto;
    align-items: center;
    background-color: $LIGHT_BLUE_TOPUP;

    @media (min-width: $SCREEN_SM_DESKTOP) {
      justify-content: space-around;
    }

    &:first {
      margin-right: 0;
    }

    .text {
      color: $QKN_BLURPLE;
      font-family: $HAFFER_BOLD;
      margin-right: 1.25rem;
    }

    .togTextCont {
      display: flex;
      align-items: center;
    }

    .infoIcon {
      height: 10px;
      margin-left: 6px;
    }
  }
}


.popUp {
  position:         absolute;
  background-color: $WHITE;
  top:              -5.625rem;
  width:            12.5rem;
  padding:          .625rem;
  box-shadow:       0 .125rem .25rem 0 rgba(0, 0, 0, 0.16), 0 -.0625rem .125rem 0 rgba(0, 0, 0, 0.05);
  border-radius:    .0625rem;

  &:after {
    content:      '';
    position:     absolute;
    bottom:       -.625rem;
    right:        3.875rem;
    width:        0;
    height:       0;
    border-left:  .625rem solid transparent;
    border-right: .625rem solid transparent;
    border-top:   .625rem solid white;
  }
}
