@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.signOutForm {
  font-size: 1rem;

  div {
    margin-top: 2.5rem;
  }
}

.contentText {
  composes: contentText;
  margin-bottom: .5rem;
  overflow: visible;
}

.bold {
  composes: contentText;
  font-weight: bold;
  overflow: visible;
}
