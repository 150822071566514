@import 'src/sass/themes/sizes';
@import '../tile.module';


.coTermWrapper {
  padding-top: 2rem;
  flex: 50% 0;
  width: 100%;
}


.coTermTitleArea {
  display: inline;
  // display: flex;
  // flex-direction: row;
}

.coTermName {
  // @extend .productName;
  display: inline-flex;
  font-family: $HAFFER_BOLD;
  &:not(:last-child) {
    // &:after {
      // content: ' \002B';
    padding-right: 0.3125rem;
    // }
  }

  .plusSign {
    display: inline-flex;
    white-space: pre-wrap;
  }
}


.subTileMiniContain {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    flex-direction: row;
  }

}