@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}


.loadingWrapper {
  display:         flex;
  flex-direction:  column;
  justify-content: center;
  align-items:     center;
  height:          100%;
}


.loadingCircle {
  animation: rotate-loader 1.25s linear infinite;
  height:    6.25rem;
  margin:    -1.875rem .3125rem 0 0;
  transform: rotate(360deg);
}
