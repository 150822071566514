@import 'src/sass/buttons.module.scss';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import '../new-modal.module';


.modalBody {
  @media (min-width: $SCREEN_SM_DESKTOP) {
    padding: 2.1875rem 4.688rem;
  }
}

.cancelModal {  
  ul {
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    margin-bottom: 1.25rem;


    @media (min-width: $SCREEN_SM_DESKTOP) {
      display:               grid;
      grid-template-columns: 11rem 1fr;
      margin:                0 0 0 2rem;

    }
    @media (min-width: $SCREEN_WIDE) {
      grid-template-columns: 14rem 1fr;
    }
  }

  li {
    position:        relative;
    display:         flex;
    align-items:     flex-start;
    justify-content: flex-start;

    @media (min-width: $SCREEN_TABLET) {
      padding-left: .75rem;
    }

    @media (min-width: $SCREEN_SM_DESKTOP) {
      margin-bottom: .625rem;
      font-size:     .875rem;
      align-self: center;
    }

    svg {
      flex:         0 1 1.5rem;
      height:       1.5rem;
      width:        1.5rem;
      margin-right: 1rem;
    }

    span {
      flex:        1 0;
      // font-size:   .75rem;
      font-size:   .0.875rem;
      font-weight: bold;
      font-family: $HAFFER;
      color:       $BLUE_HEADING;
      align-self:  center;
    }
  }
}

.label p {
  color: $TAG_LINE;
}

.btnCols {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex-basis: 70%;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

  }

  button, .cancelBtn {
    margin-bottom: 0.313rem;
    border-radius: 1.25rem;
    width: 190px;

    @media (min-width: $SCREEN_TABLET) {
      margin-bottom: 1.25rem;
    }

    @media (min-width: $SCREEN_SM_DESKTOP) {
      padding: 0.625rem 1.125rem;
      width: unset;
    }

    @media(min-width: $SCREEN_WIDE) {
      font-size: 1.125rem;
    }

    &:last-child {
      margin: 0 auto;
    }
  }

  .btnRow {
    display:         flex;
    height:          4.375rem;
    flex-direction:  column;
    justify-content: space-between;
    max-width: 11.875rem;



    &.cancelRow {
      order: 3;
      margin-right: 0;

      @media (min-width: $SCREEN_SM_DESKTOP) {
        order: 1;
        margin-right: 1rem;
      }
    }

    &.remindRow {
      order: 2;
      margin-right: 0;
      margin-bottom: 1rem;

      @media (min-width: $SCREEN_SM_DESKTOP) {
        order: 2;
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }

    &.keepRenewRow {
      order: 1;
      margin-bottom: 1rem;

      @media (min-width: $SCREEN_SM_DESKTOP) {
        order: 3;
        margin-bottom: 0;
      }
    }

    &:first-child {
      margin-bottom: 1.25rem;
    }

    .remindBtn {
      border: 0.125rem solid $QKN_BLURPLE;
      color: $QKN_BLURPLE;
      padding: 0.5rem 1rem;
      margin-bottom: 0.313rem;
    }

    .remindTxt {
      color: $TAG_LINE;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.938rem;
      text-align: center;
      font-weight: 600;
    }

    .cancelBtn {
      // composes:  contentResetBtn;
      border: 0.125rem solid $QKN_BLURPLE;
      color: $QKN_BLURPLE;
      font-weight: 700;
      line-height: 0.938rem;
      order: 4;
      line-height: 12px;

      @media (min-width: $SCREEN_SM_DESKTOP) {
        order: 1;
      }
      @media (min-width: $SCREEN_WIDE) {
        line-height: 17px;
      }
    }
    
    @media (min-width: $SCREEN_TABLET) {
      flex-direction: column;
      height:         unset;
    }

    @media (min-width: $SCREEN_SM_DESKTOP) {
      justify-content: flex-start;

      &:first-child {
        margin-right: 1.25rem;
      }
    }
  }
}







.productCard {
  position:      relative;
  border-radius: .8125rem;
  margin-bottom: 1.875rem;
  padding:       1.5rem 1.25rem .9375rem;
  box-shadow:    0 .375rem .75rem .0625rem rgba(0, 0, 0, 0.24);

  @media (min-width: $SCREEN_SM_DESKTOP) {
    max-width: 16.5rem;
    padding:   .9375rem 1.25rem;
  }

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.standard):before,
  &:global(.plus):before,
  &:global(.extra):before,
  &:global(.volume50):before,
  &:global(.volume100):before,
  &:global(.volume200):before,
  &:global(.starter):before,
  &:global(.deluxe):before,
  &:global(.premier):before,
  &:global(.hab):before,
  &:global(.hab-rpm):before,
  &:global(.cash-manager):before,
  &:global(.qob-basic):before {
    background-color: $QKN_RED;
  }

  &:global(.acme-basic):before {
    background-color: $QKN_BLURPLE;
  }

  @media (min-width: $SCREEN_SM_DESKTOP) {
    box-shadow: 0 .75rem 1.5rem .125rem rgba(0, 0, 0, 0.24);
    padding:    2.75rem 2.1875rem;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    padding: 2rem 2.25rem;
  }

  img {
    height: 1.875rem;
    @media (min-width: $SCREEN_SM_DESKTOP) {
      height: 3.4375rem;
    }
  }
}


.midRow {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    display:     flex;
    flex-direction: row;
    align-items: center;
  }
}


.renewalMsg {
  margin-top:  1rem;
  font-size:   .875rem;
  font-family: $HAFFER;
  color:       $TAG_LINE;
  flex:        0 1 12.5rem;
}


.genesys {
  display:          flex;
  align-items:      center;
  background-color: $GENESYS_YELLOW;
  padding:          1rem;
  border-radius:    .5rem;
  margin-bottom:    2rem;

  img {
    height:       1.5rem;
    width:        1.5rem;
    margin-right: 1rem;
    align-self:   flex-start;
  }

  p {
    font-family: $HAFFER;
    font-size:   .875rem;
  }
}


.chatBtn {
  composes:    linkBtn;
  font-size:   .875rem;
  font-family: $HAFFER;
}
