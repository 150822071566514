@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.passwordForm {
  display:      grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'newPassword'
          'oldPassword';

  @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'newPassword oldPassword';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;
    grid-template-columns: 47.5% 47.5%;

  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap:          2.625rem;
    grid-template-rows:    1.125rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       2.5rem;
  }
}


.warning {
  font-family:   $HAFFER;
  color:         $TAG_LINE;
  font-weight:   bold;
  font-size:     .875rem;
  margin-bottom: 1.375rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin-bottom: 2rem;
  }
}


.newPasswordField {
  grid-area: newPassword;

  [data-testid='PASSWORD_FORM_NEW_ERROR'] {
    height: unset;
    padding-bottom: 0.25rem;
  }
}


.currentPasswordInput {
  grid-area: oldPassword;
}

.mfaMethodSelect {
  grid-area: mfa;
}

.mfaMethodSelect {
  grid-area: mfaCode;
}


.checkboxField {
  display:     flex;
  align-items: center;

  label {
    margin-left: .625rem;
    color:       $TAG_LINE;
    font-family: $HAFFER;
    cursor:      pointer;
  }

  input {
    cursor: pointer;
  }
}


.mfaMsgContain {
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 17px;
  font-family: $HAFFER;
  font-size: 12px;
  line-height: 18px;
}

.mfaInputContain {
  display:      grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'mfaCode'
          'mfa';

    @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'mfaCode mfa';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;

  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap:          2.625rem;
    grid-template-rows:    1.125rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       2.5rem;
  }

  .mfaCodeInputHidden {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  .mfaMethodSelect {
    width: 100%;
  }

  [data-testid='MFA_CODE_LABEL'], [data-testid='MFA_CODE_INPUT'] {
    display: none;
  }
}

.pwMfaContainer {
  [data-testid='MFA_RESEND_MSG'] {
    display: none;
    margin-bottom: 2rem;
  }
}

.mfaSecMsg {
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 17px;
  font-family: $HAFFER;
  font-size: 12px;
  line-height: 18px;

  color: $TAG_LINE;
  background-color: $GREY_HEADER;
  border: 1px solid $GREY_BORDER;
}

.mfaCodeMsg {
  border-radius: 12px;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 17px;
  font-family: $HAFFER;
  font-size: 12px;
  line-height: 18px;

  color: $BLUE_HEADING;
  background: $LIGHT_BLUE_BG;
  border: 1px solid $LIGHT_BLUE_BG;
}

.resendText {
font-size: 12px;
  .resendLink {
    cursor: pointer;
    color: $QKN_BLURPLE;
  }
}

// .newTextField {
//   .textFieldError {
//     margin-bottom: 40px;

//     @media(min-width: $SCREEN_TABLET) {
//       margin-bottom: 40px;

//     }
//   }
// }

