@import '../../../sass/themes/fonts';
@import '../../../sass/themes/sizes';
@import '../../../sass/themes/colors';
@import '../../../sass/layouts/main.module';
@import '../../../sass/themes/pallet-colors';

footer {
  border-top: 0.0625rem solid #CED0D3;
  $font-family: $HAFFER;
  padding: 0px 20px;
  max-width: 75rem;
  margin: 0 auto;

  @media screen and (min-width(75rem)) {
    margin: 0 auto;
    padding: 0;
  }

  .top {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    justify-content: space-between;
    border-top: 1px solid #EAECF7;
    padding-top: 40px;

    @media (min-width: $SCREEN_SM_DESKTOP) {
      flex-direction: row;
    }

    .left {
      @media (min-width: ($SCREEN_NEW_DESKTOP - 1px)) {
        width: 50%;
      }

      img {
        width: 213px;
        height: 41px;
        display: block;

        @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
          width: 124px;
          height: 24px;
        }
      }

      .socialLink {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 41px;
        height: 40px;
        margin-top: 40px;
        margin-right: 15px;
        background-color: #F3F8FD;
        border-radius: 20px;

        @media screen and (max-width: 389px) {
          margin-bottom: 20px;
        }

        @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        &:hover svg {
          .socialSvg {
            fill: #F3F8FD;
          }
        }

        &:hover {
          background-color: $QKN_BLURPLE;
        }
      }
    }

    .right {
      .footerMenu {
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
        // color: black;

        @media screen and (min-width: 380px) {
          flex-direction: row;
        }
        a {
          color: black;
          color: rgb(73, 73, 73);
          text-decoration: none;
        }

        .menu {
          padding-top: 40px;
          line-height: 22px;

          @media (min-width: $SCREEN_SM_DESKTOP) {
            padding-top: 0px;
          }

          &:last-child {
            padding-left: 0px;

            @media screen and (min-width: 380px) {
              padding-left: 28px;
            }

            @media (min-width: $SCREEN_NEW_DESKTOP) {
              padding-left: 40px;
            }
          }

          @media (max-width: $SCREEN_SM_DESKTOP) {
            padding-left: 90px;

            &:first-of-type {
              padding-left: 0;
            }
          }

          span {
            display: inline-block;
            font-weight: 600;
            padding-bottom: 24px;
          }

          ul {
            padding-bottom: 30px;

            li {
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }

  .middle {
    padding-top: 40px;
    border-top: 1px solid #EAECF7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
      flex-direction: column-reverse;
    }

    .left {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;

      @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
        width: auto;
      }

      .footnotesToggle {
        &:hover {
          cursor: pointer;
        }

        padding-bottom: 20px;
      }

      .dnsLink {
        padding-bottom: 20px;
      }

      .footnotesText {
        margin-bottom: 20px;
        font-size: 14px;

        p {
          line-height: 150%;
          margin-bottom: 10px;
        }
      }
    }

    .right {
      padding-top: 30px;
      font-weight: 600;

      .downloadTitle {
        font-weight: 600;
        ;
      }

      .downloadIcon {
        padding-top: 10px;
        padding-bottom: 20px;

        .qIcon {
          padding-right: 50px;

          @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
            height: 28px;
            padding-right: 10px;
          }
        }

        .appstoreIcon {
          width: 128px;
          height: 38px;

          @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
            width: 96px;
            height: 28px;
          }
        }
      }
    }
  }

  .bottom {
    border-top: 1px solid #EAECF7;
    padding-top: 40px;
    padding-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .left {
      color: #494949;
      width: 60%;

      @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
        width: 100%;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;

      @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
        width: 100%;
        flex-direction: column;
        padding-top: 10px;
      }

      .legalLink {
        padding-left: 30px;

        @media (max-width: ($SCREEN_NEW_DESKTOP - 1px)) {
          padding: 10px 30px 10px 0;
        }
      }
    }
  }
}