@import 'src/sass/buttons.module.scss';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.modal {
  overflow-y:       auto;
  top:              50%;
  left:             50%;
  position:         relative;
  transform:        translate(-50%, -50%);
  border-radius:    1.25rem;
  background-color: $WHITE;
  width:            90%;
  min-width:        18rem;
  max-height:       95%;
  max-width:        46.25rem;

  @media(min-width: $SCREEN_WIDE) {
    max-width: 57.125rem;
  }
}


.modalHeader {
  display:                 flex;
  align-items:             center;
  justify-content:         center;
  border-top-right-radius: inherit;
  border-top-left-radius:  inherit;
  background-color:        $GREY_BORDER;
  top:                     0;
  z-index:                 110;
  padding:                 .625rem;
  width:                   100%;

  &.dbTermsHeader {
    position: sticky;
  }

  @media(min-width: $SCREEN_TABLET) {
    height: 3.25rem;
  }
}


.logoArea {
  display:         flex;
  justify-content: space-between;

  @media(min-width: $SCREEN_TABLET) {
    position: absolute;
    left:     1.125rem;
  }

  .dash {
    background-color: $LUCKY_GREY;
    margin:           0 .625rem;
    width:            .125rem;
    border-radius:    .125rem;
  }

  img {
    height: 1rem;

    @media(min-width: $SCREEN_TABLET) {
      height: 2rem;
    }
  }
}


.headingText {
  color:         $BLUE_HEADING;
  font-size:     1.125rem;
  font-family:   $HAFFER_BOLD;
  margin:        0 auto 0 1.25rem;
  padding-right: 2rem;

  &.isCentered {
    margin:        0 auto;
    padding-right: 0;
  }

  @media(min-width: $SCREEN_TABLET) {
    font-size:     1.25rem;
    margin:        unset;
    padding-right: 0;
  }
}


.closeBtnIcon {
  position:         absolute;
  top:              .375rem;
  right:            .5rem;
  padding:          0;
  border:           0;
  cursor:           pointer;
  background-color: transparent;
  outline:          none;

  @media(min-width: $SCREEN_TABLET) {
    top:   .75rem;
    right: 1rem;
  }

  svg {
    height:         1.875rem;
    width:          1.875rem;
    opacity:        .7;
    user-select:    none;
    pointer-events: none;
  }
}


.modalBody {
  transition: padding ease 200ms;
  padding:    .875rem .9375rem;

  &.dbTermsModal {
    padding: 0;
  }

  @media(min-width: $SCREEN_TABLET) {
    padding: 1.5rem 2.1875rem;
  }

  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding: 2.1875rem 1.875rem;
  }

  @media(min-width: $SCREEN_WIDE) {
    padding: 2.4375rem 2rem;
  }
}


.btnRow {
  display:         flex;
  justify-content: flex-end;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    button:last-child {
      margin-left: 2rem;
    }
  }
}
