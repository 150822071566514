@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.nameForm {
  display:      grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'fNameContent'
          'fNameInput'
          'lNameContent'
          'lNameInput';

  @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'fNameContent lNameContent'
          'fNameInput lNameInput';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;
  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap:          2.625rem;
    grid-template-rows:    3.5rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       2.5rem;
  }
}


.fNameContent {
  grid-area: fNameContent;
  overflow:  hidden;

  p {
    text-overflow: ellipsis;
    white-space:   nowrap;
  }
}


.lNameContent {
  grid-area: lNameContent;
  overflow:  hidden;

  p {
    text-overflow: ellipsis;
    white-space:   nowrap;
  }
}


.fNameInput {
  grid-area: fNameInput;
}


.lNameInput {
  grid-area: lNameInput;
}
