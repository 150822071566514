@import 'src/sass/themes/new-form.modules';
@import 'src/sass/themes/sizes';
@import '../new-modal.module';


.qknIdForm {
  display: grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'quickenIdLabel'
          'mfaSecMsg'
          'qknIdInput'
          'passwordInput';

  @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'quickenIdLabel quickenIdLabel'
          'qknIdInput passwordInput';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;
  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap: 2.625rem;
    grid-template-rows: 7.5rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.5rem;
  }
}

.quickenIdLabel {
  grid-area: quickenIdLabel;
  // overflow: hidden;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.qknIdInput {
  grid-area: qknIdInput;
}
.passwordInput {
  grid-area: passwordInput;
}


.emailMfaContainer {
  [data-testid='MFA_RESEND_MSG'] {
    display: none;
    margin-bottom: 2rem;
  }
}

.mfaInputContain {
  display:      grid;
  grid-row-gap: 1.375rem;
  grid-template-areas:
          'mfaCode'
          'mfa';

    @media(min-width: $SCREEN_SM_DESKTOP) {
    grid-template-areas:
          'mfaCode mfa';
    grid-row-gap: 2rem;
    grid-column-gap: 1.875rem;

  }

  @media(min-width: $SCREEN_WIDE) {
    grid-row-gap:          2.625rem;
    grid-template-rows:    2.125rem 5rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       2.5rem;
  }

  .mfaCodeInputHidden {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  .mfaMethodSelect {
    width: 100%;
  }

  [data-testid='MFA_CODE_LABEL'], [data-testid='MFA_CODE_INPUT'] {
    display: none;
  }
}


.mfaSecMsg {
  border-radius: 12px;
  margin-bottom: 1rem;
  margin-top: 1rem !important;
  padding: 17px;
  font-family: $HAFFER;
  font-size: 12px;
  line-height: 18px;

  color: $TAG_LINE;
  background-color: $GREY_HEADER;
  border: 1px solid $GREY_BORDER;

  white-space: unset !important;
}

.mfaCodeMsg {
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 17px;
  font-family: $HAFFER;
  font-size: 12px;
  line-height: 18px;

  color: $BLUE_HEADING;
  background: $LIGHT_BLUE_BG;
  border: 1px solid $LIGHT_BLUE_BG;
}



.resendText {
font-size: 12px;
  .resendLink {
    cursor: pointer;
    color: $QKN_BLURPLE;
  }
}
