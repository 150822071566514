@import 'src/sass/themes/sizes';
@import '../tile.module';



.advertContain {
  flex: 1 0;
  width: 100%;
  position: relative;

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    margin-right: 2.5rem;
  }

  .adverts {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    @media (min-width: $SCREEN_NEW_DESKTOP) {
      flex-direction: row;
    }
  }
}

.advertTitle {
  padding-top: 12px;
  padding-bottom: 32px;

  font-size: 1.125rem;
}