@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import '../info-area.module';


.qknInfo {
  display:        flex;
  flex-direction: column;
  padding:        1.9375rem .9375rem 1.375rem;

  .caPriceWarn {
    font-weight: 700;
    margin-top: 0.938rem;
    margin-bottom: 0px;
    font-size: 0.688rem;
  }

  @media (min-width: $SCREEN_TABLET) {
    padding: 0 0 1.5rem;
  }
  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex-direction: row;
  }
}

.prodName {
  font-family: $HAFFER_BOLD;
  margin-bottom: .5rem;
}

.tagLine {
  font-family: $HAFFER;
  margin-bottom: 0.75rem;
}

.leftSide {
  text-align:    left;
  margin-bottom: .3125rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    flex: 1 0;
  }
}


.rightSide {
  text-align:  left;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    margin-left: 0;
    text-align:  right;
    flex:        0 1 13.75rem;

    .range {
      text-align: right;
    }
  }
}

.remainingTime {
  font-family: $HAFFER_BOLD;
  margin-bottom: .5rem;
}

.cancelBox {
  display: inline-block;
  margin-bottom: .5rem;
}
