@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../tile.module';

.bmTile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $ADDON_GREY;
  border: 0.0625rem solid $ADDON_BORDER_GREY;
  padding: 0.75rem;
  border-radius: 0.75rem;
  justify-content: space-between;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    max-width: 14.875rem;
  }
}

.bmModalTile {
  composes: tile;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: .9375rem 0 0;
  padding: 1.5625rem 1.875rem;

  @media (min-width: $SCREEN_SM_DESKTOP) {
    max-width: 21.25rem;
    margin: 0 0 0 1rem;
    width: 17rem;
    align-self: center;
  }
}

.payCounts {
  font-family: $HAFFER;
  font-size: 1rem;
  line-height: 1.5625rem;
}
