@import 'src/sass/themes/new-form.modules';
@import '../new-modal.module';

.currentAddress {
  padding: 0 0 1.5625rem;
  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding-bottom: 3.4375rem;
  }
}


.addressForm {
  padding:               0 0 3.4375rem;
  display:               grid;
  grid-template-rows:    1fr;
  grid-template-columns: 1fr;
  grid-row-gap:          1.375rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding:               0 0 3.4375rem;
    grid-template-columns: 1fr 1fr;
    grid-column-gap:       2.5rem;
    grid-row-gap:          2.625rem;
  }
}
