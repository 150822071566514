@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';


.navTab {
  height:           3.375rem;
  display:          flex;
  width:            100%;
  align-items:      center;
  padding:          .75rem 1.3125rem 1rem;
  background-color: transparent;
  border:           0;
  text-decoration:  none;
  font-family:      $HAFFER;
  color:            $BLUE_HEADING;
  outline:          none;
  cursor:           pointer;

  img {
    height:       1.5rem;
    margin-right: 1rem;
  }
}


.navTab.active {
  background-color: $ACTIVE_TAB;
}

.payLinkContain {
  display: flex;
  justify-content: space-between;

  .errIcon {
    margin-left: 1.5625rem;
  }
}
