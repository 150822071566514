@import 'src/sass/themes/colors';
@import 'src/sass/buttons.module';
@import 'simp-mobile.module';


.secondaryHeading {
  font-family:   $HAFFER;
  font-size:     1rem;
  color:         $BLUE_HEADING;
  margin-bottom: 1.5rem;
  margin-top:    2rem;
}


.benefitList {
  font-family:   $HAFFER;
  margin-bottom: 2rem;

  li {
    font-size:     .75rem;
    margin-left:   1.5rem;
    margin-bottom: 1rem;
    position:      relative;
    font-family:   $HAFFER;
    color:         $BLUE_HEADING;

    &:before {
      content:          '';
      position:         absolute;
      width:            .375rem;
      height:           .75rem;
      display:          block;
      top:              0;
      left:             -1.25rem;
      background-color: transparent;
      border-bottom:    .125rem solid $GREEN_SEA;
      border-right:     .125rem solid $GREEN_SEA;
      transform:        rotate(45deg);
      border-top:       0;
    }
  }
}


.rocket {
  display: none;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    display:  block;
    position: absolute;
    bottom:   6.5rem;
    right:    -1.25rem;
    height:   10rem;
  }

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    display: none;
  }
  @media (min-width: $SCREEN_WIDE) {
    display: block;
  }
}
