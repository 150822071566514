@import 'src/sass/themes/sizes';

.checkBoxField {
  display:       flex;
  align-items:   center;

  input {
    margin-right: .3125rem;
  }
}
