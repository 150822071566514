@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';

.tile {
  border-radius:    .8125rem;
  padding:          2rem 1.875rem;
  background-color: $WHITE;
  position:         relative;
  box-shadow:       0 .375rem .75rem 0 rgba(0, 0, 0, 0.16), 0 -.0625rem .375rem 0 rgba(0, 0, 0, 0.05);
  // margin-bottom:    0.5rem;
  margin-bottom:    1.875rem;

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    // max-width:  25rem;
    box-shadow: 0 .75rem 1.5rem .125rem rgba(0, 0, 0, 0.24);
  }

  &:before {
    content:                 '';
    position:                absolute;
    top:                     0;
    height:                  .75rem;
    width:                   100%;
    left:                    0;
    border-top-right-radius: inherit;
    border-top-left-radius:  inherit;
  }

  &:global(.standard):before,
  &:global(.plus):before,
  &:global(.extra):before,
  &:global(.volume50):before,
  &:global(.volume100):before,
  &:global(.volume200):before,
  &:global(.starter):before,
  &:global(.deluxe):before,
  &:global(.premier):before,
  &:global(.hab):before,
  &:global(.hab-rpm):before,
  &:global(.cash-manager):before,
  &:global(.qob-basic):before {
    background-color: $QKN_RED;
  }

  &:global(.acme-basic):before,
  &:global(.acme):before,
  &:global(.coterm):before,
  &:global(.qem):before {
    background-color: $QKN_BLURPLE;
  }

  &:global(.advert):before {
    background-color: $LIGHT_BLUE_TOPUP;
  }
}

.productAlert {
  // margin:           1.125rem 0 0;
  // color:            $BLUE_HEADING;
  // background-color: rgba(207, 236, 248, 0.2);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: $RED_OPAQUE;
  border:           solid .0625rem $CORAL;
  border-radius:    .5rem;
  // padding:          .5rem .875rem;
  padding:          1.5rem;
  margin:           1.5rem 0;
  font-size:        .8125rem;
  line-height:      1.125rem;

  .messageTitle {
    font-size: 1.0625rem;
    font-weight: 600;;
  }
}

.dash {
  display:          block;
  width:            100%;
  background-color: $DIVIDER_GREY;
  height:           .0625rem;
  margin:           0 0 1.5rem;

  &.miniSub {
    margin-top: 1.5rem;
  }
}

.dash.priceMsg {
  margin-top: 2rem;
}

.productName {
  font-size: 1.125rem;
  color: $BLUE_HEADING;
}

.productExpiry {
  margin-top: 1.125rem;
  margin-bottom: 0.75rem;
  @media (min-width: $SCREEN_NEW_DESKTOP) {
    margin-top: 0;
  }
}

.ctaBtn {
  font-family: $HAFFER_BOLD;
  color: $QKN_BLURPLE;
  font-size: .8125rem;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.qknName {
  @extend .productName;
  font-family: $HAFFER_BOLD;
}

.acmeName {
  @extend .productName;
  font-family: $HAFFER_BOLD;
}

.coTermName {
  @extend .productName;
  font-family: $HAFFER_BOLD;
}

.tagLine {
  font-size:   .75rem;
  font-family: $HAFFER;
  color:       $TAG_LINE;
  margin: 0.75rem 0;
}

// .qknTagLine {
//   @extend .tagLine;
//   font-family: $HAFFER;
// }

// .acmeTagline {
//   @extend .tagLine;
//   font-family: $HAFFER;
// }

.price {
  &.hasAddons {
    // margin-bottom: 1.125rem;
    @media (min-width: $SCREEN_NEW_DESKTOP) {
      margin-bottom: 1.5rem;
    }
  }
}


.subTileTop {
  display: flex;
  flex-direction: column;
  @media (min-width: $SCREEN_NEW_DESKTOP) {
    flex-direction: row;
  }

  .subTileLeft {
    @media (min-width: $SCREEN_NEW_DESKTOP) {
      // width: 50%;
      flex: 1 0;
    }
  }

  .subTileRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media (min-width: $SCREEN_NEW_DESKTOP) {
      // width: 50%;
      flex: 1 1;
      align-items: flex-end;
    }

    .actionBtnWrapper {
      &.hasAddons {
        margin-bottom: 1.125rem;
    
        @media (min-width: $SCREEN_NEW_DESKTOP) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.subTileBtm {
  flex: 1;
  
  .addOnTitle {
      font-size: 0.875rem;
      font-weight: 600;
    }

  .addonContain {
    display: flex;
    gap: 24px;
    flex-direction: column;

    @media (min-width: $SCREEN_NEW_DESKTOP) {
      flex-direction: row;
    }


  }
}