@import 'src/sass/themes/sizes';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';


.cardWrapper {
  display:               grid;
  grid-template-columns: 1fr;
  grid-row-gap:          1.25rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 0fr);
  grid-template-areas:
    "a"
    "b"
    "c"
    "d"
    "e";
  

  @media (min-width: $SCREEN_WIDE) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "a b" 
      "c d"
      "e e";

    grid-column-gap:       2rem;
    grid-row-gap:          2rem;
  }
}


.overViewCard {
  border-radius:    .8125rem;
  box-shadow:       0 .375rem .75rem .0625rem rgba(0, 0, 0, 0.24);
  text-align:       center;
  padding: 2.625rem 2.5rem;
  border:           0;
  cursor:           pointer;
  background-color: $WHITE;
  outline:          none;
  text-decoration:  none;

  @media (min-width: $SCREEN_NEW_DESKTOP) {
    box-shadow: 0 .75rem 1.5rem .125rem rgba(0, 0, 0, 0.24);
    padding: 1.5rem 2.5rem;
  }

  svg {
    height:        3rem;
    margin-bottom: 1.5625rem;
    fill:          $RED_ICON;
  }

  h2 {
    color:         $BLUE_HEADING;
    font-family:   $HAFFER;
    font-size:     1.5rem;
    margin-bottom: 1.875rem;
  }

  p {
    font-family:   $HAFFER;
    font-size:     1rem;
    color:         $MED_GREY;
    margin-bottom: 1.875rem;
    height: 4rem;
  }

  h3 {
    font-size: 1rem;
    color:     $QKN_BLURPLE;
  }

  a {
    display: inline-block;
  }
}

.paymentsCard {

}
.downloadCard {
  composes: overViewCard;
  cursor:   default;
  grid-area: e;

  .dlTitle {
    display: flex;
    .downloadSvg {
      height: 1.875rem;
    }
    img, h2 {
      margin-bottom: 0;
    }
  }

  .dlLinkContain {
    display: flex;
    flex-direction: column;
    margin-top: 2.563rem;
    gap: 3rem;

    @media (min-width: $SCREEN_WIDE) {
      flex-direction: row;
    }

    .dlLinkTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.5rem;
      text-align: left;
      font-size: 1.063rem;

      .dlLogo {
        height: 1.563rem;
        margin-right: 0.75rem;
      }

      .qknRed {
        color: $QKN_RED;
      }

      .qknBlurple {
        color: $QKN_BLURPLE;
      }
    }

    .qcDownloads, .qsDownloads {
      display: flex;
      flex-direction: column;
      justify-content: space-between;;

      .appIcon {
        @media (min-width: $SCREEN_NEW_DESKTOP) {
          &:not(:last-child) {
            margin-right: 12px;
          }
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        @media (min-width: $SCREEN_NEW_DESKTOP) {
          flex-direction: row;
        }
      }
    }
    .qcDownloads {
      .appIcon {
        img {
          height: 2.875rem;
          width: auto;

          @media (min-width: $SCREEN_NEW_DESKTOP) {
            flex-direction: row;
            height: 2.5rem;
            width: auto;
          }
        }
      }
    }

    .qsDownloads {
      .appIcon {
        img {
          width: 7.5rem;
          height: auto;

          @media (min-width: $SCREEN_NEW_DESKTOP) {
            height: 2.188rem;
            width: auto;
          }
        }
      }
    }
  }
}


.linkCta {
  color:      $QKN_BLURPLE;
  background: transparent;
  outline:    none;
  border:     0;
}


.svgIcon {
  height: 1.5rem;
  margin-bottom: 1.125rem;
}


.appIcon {
  img {
    height: 2.5rem;
  }
}
