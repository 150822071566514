@import 'src/sass/themes/pallet-colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';

.errorWrapper {
  width: 37.5rem;
  margin: 6.25rem auto 0;
}

.site {
  height: 100%;
  width: 100%;
}

.navIsOpen {
  position: fixed;
  @media (min-width: $SCREEN_SM_DESKTOP) {
    position: initial;
  }
}

//keep below .navIsOpen because bottom overwrites top classes
.hasModal {
  position: fixed;
}
