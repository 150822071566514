@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/buttons.module';
@import '../../new-modal.module';


.modalWrapper {
  font-family: $HAFFER;
  overflow:    hidden;
  height:      100%;
  max-height:  28.375rem;

  @media(min-width: $SCREEN_TABLET) {
    max-height: 34rem;
  }

  @media(min-width: $SCREEN_SM_DESKTOP) {
    max-height: 45rem;
  }
}


.switchSuccessArea {
  display:        flex;
  flex-direction: column;
  padding:        .5rem 1.5rem 0;
  text-align:     center;
  align-items:    center;
  height:         calc(100% - 2.5rem);

  @media(min-width: $SCREEN_TABLET) {
    height: calc(100% - 3.25rem);
  }

  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding: 2.75rem 3rem 0;
  }
}


.scalable {
  flex:       0 1 22rem;
  overflow-y: auto;
  @media(min-width: $SCREEN_TABLET) {
    flex: 0 1 26rem;
  }
  @media(min-width: $SCREEN_SM_DESKTOP) {
    flex: 0 1 30rem;
  }
}


.thumb {
  height: 6rem;

  @media(min-width: $SCREEN_TABLET) {
    height: 7.5rem;
  }
}


.heading {
  font-size:     1.125rem;
  line-height:   1.25rem;
  margin-bottom: .5rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    font-size:     1.5rem;
    line-height:   1.875rem;
    margin-bottom: 1rem;
  }
}


.access {
  font-size:   .75rem;
  max-width:   21rem;
  margin:      0 auto 1rem;
  color:       $MED_GREY;
  line-height: 1.25rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    font-size: 1rem;
    margin:    0 auto 2.5rem;
  }

  span {
    font-family: $HAFFER;
  }
}


.howTo {
  border-top: solid .0625rem #EBEFF2;
  margin:     0 auto;
  padding:    1rem 0 0;
  text-align: left;
  color:      $MED_GREY;
  width:      100%;
  max-width:  30rem;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    padding: 2.5rem 0 0;
  }


  h4 {
    margin-bottom: .75rem;
    font-size:     .75rem;

    @media(min-width: $SCREEN_TABLET) {
      font-size:     1rem;
      margin-bottom: 1.5rem;
    }
  }

  ul {
    padding-left:  2rem;
    margin-bottom: 1.5rem;

    @media(min-width: $SCREEN_TABLET) {
      padding-left:  3rem;
      margin-bottom: 2.25rem;
    }

    li {
      font-size:     .75rem;
      line-height:   1rem;
      margin-bottom: .5rem;
      position:      relative;

      @media(min-width: $SCREEN_TABLET) {
        font-size:     1rem;
        line-height:   1.25rem;
        margin-bottom: 1rem;
      }

      span {
        position:         absolute;
        background-color: #E0EEFB;
        padding:          .75rem;
        display:          flex;
        top:              0;
        left:             -2rem;
        height:           1rem;
        width:            1rem;
        border-radius:    100%;
        justify-content:  center;
        align-items:      center;
        font-size:        .875rem;

        @media(min-width: $SCREEN_TABLET) {
          left:    -3rem;
          height:  2.25rem;
          width:   2.25rem;
          padding: 1rem;
        }
      }

      a {
        color:           $QKN_BLURPLE;
        text-decoration: none;
      }
    }
  }


  h5 {
    color:       $LEGAL_GREY;
    font-size:   .625rem;
    font-weight: normal;
    text-align:  left;

    @media(min-width: $SCREEN_TABLET) {
      font-size: .75rem;
    }
  }
}


.btnRow {
  display:     flex;
  flex:        0 1 2rem;
  margin:      1rem 0;
  width:       100%;
  align-items: flex-start;

  @media(min-width: $SCREEN_SM_DESKTOP) {
    margin: 3rem 0 3.75rem;
  }
}


.closeCtaBtn {
  composes: contentCTABtn;
  margin:   0;
  display:  block;
  width:    100%;

  @media(min-width: $SCREEN_TABLET) {
    width:  auto;
    margin: 0 0 0 auto;
  }
}
