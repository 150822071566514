@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';


.expiry {
  color:            $BLUE_HEADING;
  background-color: $ACTIVE_TAB;
  font-family:      $HAFFER_BOLD;
  font-size:        .8125rem;
  padding:          .3125rem .625rem;
  border-radius:    .375rem;
  display:          inline-block;
}


.inlineExpiry {
  color:            $TAG_LINE;
  background-color: $WHITE;
  font-family:      $HAFFER;
  font-size:        .8125rem;
  border-radius:    .375rem;
  display:          inline-block;
  text-align:       left;
  padding:          0;
}

.warning {
  color: $QKN_RED;
  background-color: $RED_OPAQUE;
}